import _ from 'lodash';
import React, { Component } from 'react';
import { observer, Observer } from 'mobx-react';
import { types } from 'mobx-state-tree';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Dropdown, Form, Segment, Header } from 'semantic-ui-react'
import chapterBreakdown from './chapterBreakdown';
import chapterVersesBreakdown from './chapterVersesBreakdown';
import moment from 'moment';
import './App.css';

const { model, optional, number, string } = types;

const chapterOptions = _.map(chapterBreakdown, (bookChapter, index) => {
  return {
    key: index,
    value: index,
    text: bookChapter
  }
});

const MomentDate = types.custom({
  name: 'MomentDate',
  fromSnapshot(value) {
    return moment(value);
  },
  toSnapshot(value) {
    return value.toDate();
  },
  isTargetType(value) {
    return this.isValidSnapshot(value);
  },
  isValidSnapshot(value) {
    return moment(value).isValid();
  },
});

const Breakdown = model('Breakdown', {
  startDate: optional(MomentDate, () => moment()),
  endDate: optional(MomentDate, () => moment().endOf('year')),
  startBookChapterIndex: optional(number, 0),
  unit: optional(string, 'chapter'),
})
.actions(self => ({
  setStartDate(startDate) {
    if (startDate.isAfter(self.endDate)) {
      return;
    }
    self.startDate = startDate;
  },
  setEndDate(endDate) {
    if (endDate.isBefore(self.startDate)) {
      return;
    }
    self.endDate = endDate;
  },
  setStartBookeChapterIndex(startBookChapterIndex) {
    self.startBookChapterIndex = startBookChapterIndex;
  },
  setUnit(unit) {
    self.unit = unit;
  }
}))
.views(self => ({
  get unitBreakdown() {
    if (self.unit === 'chapter') {
      return chapterBreakdown;
    }
    return chapterVersesBreakdown;
  },
  get days() {
    return self.endDate.diff(self.startDate, 'days') + 1;
  },
  get remainingUnits() {
    return _.slice(self.unitBreakdown, self.startingPlaceInBreakdown);
  },
  get unitsPerDay() {
    return self.remainingUnits.length / self.days;
  },
  get startingPlaceInBreakdown() {
    if (self.unit === 'verse') {
      return chapterVersesBreakdown.indexOf(chapterBreakdown[self.startBookChapterIndex] + ':1');
    }
    return self.startBookChapterIndex;
  },
  get readingSchedule() {
    const currentDate = self.startDate.clone();
    const schedule = [];

    let currentPlace = self.startingPlaceInBreakdown;

    while (currentDate <= self.endDate) {
      let startPlaceIndex = Math.min(parseInt(currentPlace), self.unitBreakdown.length - 1);
      const endPlaceIndex = Math.min(parseInt(currentPlace + self.unitsPerDay), self.unitBreakdown.length - 1);
      if (currentDate > self.startDate) {
        startPlaceIndex += 1;
      }

      schedule.push({
        startPlace: self.unitBreakdown[startPlaceIndex],
        endPlace: self.unitBreakdown[endPlaceIndex],
        date: moment(currentDate).format('MMMM Do YYYY')
      })
      currentDate.add(1, 'days');
      currentPlace += self.unitsPerDay;
    }
    return schedule;
  }
}))

const breakdown = Breakdown.create();

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="left">
          <Form>
            <Observer>{() => (
              <Form.Field>
                <label>Starting book/chapter</label>
                <Dropdown
                  placeholder='Select Book/Chapter'
                  fluid
                  search
                  selection
                  options={chapterOptions}
                  value={breakdown.startBookChapterIndex} 
                  onChange={(e, {value}) => breakdown.setStartBookeChapterIndex(value)}
                />
              </Form.Field> 
            )}
          </Observer>
          <Observer>{() => (
            <Form.Field>
              <label>Start date</label>
              <DatePicker
                selected={breakdown.startDate}
                selectsStart
                startDate={breakdown.startDate}
                endDate={breakdown.endDate}
                onChange={breakdown.setStartDate}
              />
            </Form.Field> 
          )}
        </Observer>

        <Observer>{() => (
          <Form.Field>
            <label>End date</label>
            <DatePicker
              selected={breakdown.endDate}
              selectsEnd
              startDate={breakdown.startDate}
              endDate={breakdown.endDate}
              onChange={breakdown.setEndDate}
            />
          </Form.Field> 
        )}
      </Observer>
      <Observer>{() => (
        <Form.Field>
          <label>Breakdown by</label>
          <Dropdown
            placeholder='Unit'
            fluid
            search
            selection
            options={[
              { key: 1, text: 'Chapter', value: 'chapter' },
              { key: 2, text: 'Verse', value: 'verse' },
            ]}
            value={breakdown.unit} 
            onChange={(e, {value}) => breakdown.setUnit(value)}
          />
        </Form.Field> 
      )}
    </Observer>

  </Form>
</div>
<div className="right">
  <Header as='h1'>Reading Schedule</Header>
  <hr />
  <Observer>{() => (
    <Segment className="unitsPerDay">
      <span>{_.round(breakdown.unitsPerDay, 1)}</span>
      <span className="units">{_.upperFirst(breakdown.unit)}s</span>
      <span className="perDay">per day</span>
    </Segment> 
  )}
</Observer>
<Observer>{() => (
  <div className='readingSchedule'>
    {_.map(breakdown.readingSchedule, day => (
      <div className='day'>
        <span className='date'>{day.date}:</span> <span className='chapters'>{day.startPlace} - {day.endPlace}</span>
      </div>          
    ))}
  </div> 
)}
      </Observer>
    </div>
    <div>
    </div>
  </div>
);
  }
}

export default observer(App);
