module.exports = [
  '1 Nephi 1:1',
  '1 Nephi 1:2',
  '1 Nephi 1:3',
  '1 Nephi 1:4',
  '1 Nephi 1:5',
  '1 Nephi 1:6',
  '1 Nephi 1:7',
  '1 Nephi 1:8',
  '1 Nephi 1:9',
  '1 Nephi 1:10',
  '1 Nephi 1:11',
  '1 Nephi 1:12',
  '1 Nephi 1:13',
  '1 Nephi 1:14',
  '1 Nephi 1:15',
  '1 Nephi 1:16',
  '1 Nephi 1:17',
  '1 Nephi 1:18',
  '1 Nephi 1:19',
  '1 Nephi 1:20',
  '1 Nephi 2:1',
  '1 Nephi 2:2',
  '1 Nephi 2:3',
  '1 Nephi 2:4',
  '1 Nephi 2:5',
  '1 Nephi 2:6',
  '1 Nephi 2:7',
  '1 Nephi 2:8',
  '1 Nephi 2:9',
  '1 Nephi 2:10',
  '1 Nephi 2:11',
  '1 Nephi 2:12',
  '1 Nephi 2:13',
  '1 Nephi 2:14',
  '1 Nephi 2:15',
  '1 Nephi 2:16',
  '1 Nephi 2:17',
  '1 Nephi 2:18',
  '1 Nephi 2:19',
  '1 Nephi 2:20',
  '1 Nephi 2:21',
  '1 Nephi 2:22',
  '1 Nephi 2:23',
  '1 Nephi 2:24',
  '1 Nephi 3:1',
  '1 Nephi 3:2',
  '1 Nephi 3:3',
  '1 Nephi 3:4',
  '1 Nephi 3:5',
  '1 Nephi 3:6',
  '1 Nephi 3:7',
  '1 Nephi 3:8',
  '1 Nephi 3:9',
  '1 Nephi 3:10',
  '1 Nephi 3:11',
  '1 Nephi 3:12',
  '1 Nephi 3:13',
  '1 Nephi 3:14',
  '1 Nephi 3:15',
  '1 Nephi 3:16',
  '1 Nephi 3:17',
  '1 Nephi 3:18',
  '1 Nephi 3:19',
  '1 Nephi 3:20',
  '1 Nephi 3:21',
  '1 Nephi 3:22',
  '1 Nephi 3:23',
  '1 Nephi 3:24',
  '1 Nephi 3:25',
  '1 Nephi 3:26',
  '1 Nephi 3:27',
  '1 Nephi 3:28',
  '1 Nephi 3:29',
  '1 Nephi 3:30',
  '1 Nephi 3:31',
  '1 Nephi 4:1',
  '1 Nephi 4:2',
  '1 Nephi 4:3',
  '1 Nephi 4:4',
  '1 Nephi 4:5',
  '1 Nephi 4:6',
  '1 Nephi 4:7',
  '1 Nephi 4:8',
  '1 Nephi 4:9',
  '1 Nephi 4:10',
  '1 Nephi 4:11',
  '1 Nephi 4:12',
  '1 Nephi 4:13',
  '1 Nephi 4:14',
  '1 Nephi 4:15',
  '1 Nephi 4:16',
  '1 Nephi 4:17',
  '1 Nephi 4:18',
  '1 Nephi 4:19',
  '1 Nephi 4:20',
  '1 Nephi 4:21',
  '1 Nephi 4:22',
  '1 Nephi 4:23',
  '1 Nephi 4:24',
  '1 Nephi 4:25',
  '1 Nephi 4:26',
  '1 Nephi 4:27',
  '1 Nephi 4:28',
  '1 Nephi 4:29',
  '1 Nephi 4:30',
  '1 Nephi 4:31',
  '1 Nephi 4:32',
  '1 Nephi 4:33',
  '1 Nephi 4:34',
  '1 Nephi 4:35',
  '1 Nephi 4:36',
  '1 Nephi 4:37',
  '1 Nephi 4:38',
  '1 Nephi 5:1',
  '1 Nephi 5:2',
  '1 Nephi 5:3',
  '1 Nephi 5:4',
  '1 Nephi 5:5',
  '1 Nephi 5:6',
  '1 Nephi 5:7',
  '1 Nephi 5:8',
  '1 Nephi 5:9',
  '1 Nephi 5:10',
  '1 Nephi 5:11',
  '1 Nephi 5:12',
  '1 Nephi 5:13',
  '1 Nephi 5:14',
  '1 Nephi 5:15',
  '1 Nephi 5:16',
  '1 Nephi 5:17',
  '1 Nephi 5:18',
  '1 Nephi 5:19',
  '1 Nephi 5:20',
  '1 Nephi 5:21',
  '1 Nephi 5:22',
  '1 Nephi 6:1',
  '1 Nephi 6:2',
  '1 Nephi 6:3',
  '1 Nephi 6:4',
  '1 Nephi 6:5',
  '1 Nephi 6:6',
  '1 Nephi 7:1',
  '1 Nephi 7:2',
  '1 Nephi 7:3',
  '1 Nephi 7:4',
  '1 Nephi 7:5',
  '1 Nephi 7:6',
  '1 Nephi 7:7',
  '1 Nephi 7:8',
  '1 Nephi 7:9',
  '1 Nephi 7:10',
  '1 Nephi 7:11',
  '1 Nephi 7:12',
  '1 Nephi 7:13',
  '1 Nephi 7:14',
  '1 Nephi 7:15',
  '1 Nephi 7:16',
  '1 Nephi 7:17',
  '1 Nephi 7:18',
  '1 Nephi 7:19',
  '1 Nephi 7:20',
  '1 Nephi 7:21',
  '1 Nephi 7:22',
  '1 Nephi 8:1',
  '1 Nephi 8:2',
  '1 Nephi 8:3',
  '1 Nephi 8:4',
  '1 Nephi 8:5',
  '1 Nephi 8:6',
  '1 Nephi 8:7',
  '1 Nephi 8:8',
  '1 Nephi 8:9',
  '1 Nephi 8:10',
  '1 Nephi 8:11',
  '1 Nephi 8:12',
  '1 Nephi 8:13',
  '1 Nephi 8:14',
  '1 Nephi 8:15',
  '1 Nephi 8:16',
  '1 Nephi 8:17',
  '1 Nephi 8:18',
  '1 Nephi 8:19',
  '1 Nephi 8:20',
  '1 Nephi 8:21',
  '1 Nephi 8:22',
  '1 Nephi 8:23',
  '1 Nephi 8:24',
  '1 Nephi 8:25',
  '1 Nephi 8:26',
  '1 Nephi 8:27',
  '1 Nephi 8:28',
  '1 Nephi 8:29',
  '1 Nephi 8:30',
  '1 Nephi 8:31',
  '1 Nephi 8:32',
  '1 Nephi 8:33',
  '1 Nephi 8:34',
  '1 Nephi 8:35',
  '1 Nephi 8:36',
  '1 Nephi 8:37',
  '1 Nephi 8:38',
  '1 Nephi 9:1',
  '1 Nephi 9:2',
  '1 Nephi 9:3',
  '1 Nephi 9:4',
  '1 Nephi 9:5',
  '1 Nephi 9:6',
  '1 Nephi 10:1',
  '1 Nephi 10:2',
  '1 Nephi 10:3',
  '1 Nephi 10:4',
  '1 Nephi 10:5',
  '1 Nephi 10:6',
  '1 Nephi 10:7',
  '1 Nephi 10:8',
  '1 Nephi 10:9',
  '1 Nephi 10:10',
  '1 Nephi 10:11',
  '1 Nephi 10:12',
  '1 Nephi 10:13',
  '1 Nephi 10:14',
  '1 Nephi 10:15',
  '1 Nephi 10:16',
  '1 Nephi 10:17',
  '1 Nephi 10:18',
  '1 Nephi 10:19',
  '1 Nephi 10:20',
  '1 Nephi 10:21',
  '1 Nephi 10:22',
  '1 Nephi 11:1',
  '1 Nephi 11:2',
  '1 Nephi 11:3',
  '1 Nephi 11:4',
  '1 Nephi 11:5',
  '1 Nephi 11:6',
  '1 Nephi 11:7',
  '1 Nephi 11:8',
  '1 Nephi 11:9',
  '1 Nephi 11:10',
  '1 Nephi 11:11',
  '1 Nephi 11:12',
  '1 Nephi 11:13',
  '1 Nephi 11:14',
  '1 Nephi 11:15',
  '1 Nephi 11:16',
  '1 Nephi 11:17',
  '1 Nephi 11:18',
  '1 Nephi 11:19',
  '1 Nephi 11:20',
  '1 Nephi 11:21',
  '1 Nephi 11:22',
  '1 Nephi 11:23',
  '1 Nephi 11:24',
  '1 Nephi 11:25',
  '1 Nephi 11:26',
  '1 Nephi 11:27',
  '1 Nephi 11:28',
  '1 Nephi 11:29',
  '1 Nephi 11:30',
  '1 Nephi 11:31',
  '1 Nephi 11:32',
  '1 Nephi 11:33',
  '1 Nephi 11:34',
  '1 Nephi 11:35',
  '1 Nephi 11:36',
  '1 Nephi 12:1',
  '1 Nephi 12:2',
  '1 Nephi 12:3',
  '1 Nephi 12:4',
  '1 Nephi 12:5',
  '1 Nephi 12:6',
  '1 Nephi 12:7',
  '1 Nephi 12:8',
  '1 Nephi 12:9',
  '1 Nephi 12:10',
  '1 Nephi 12:11',
  '1 Nephi 12:12',
  '1 Nephi 12:13',
  '1 Nephi 12:14',
  '1 Nephi 12:15',
  '1 Nephi 12:16',
  '1 Nephi 12:17',
  '1 Nephi 12:18',
  '1 Nephi 12:19',
  '1 Nephi 12:20',
  '1 Nephi 12:21',
  '1 Nephi 12:22',
  '1 Nephi 12:23',
  '1 Nephi 13:1',
  '1 Nephi 13:2',
  '1 Nephi 13:3',
  '1 Nephi 13:4',
  '1 Nephi 13:5',
  '1 Nephi 13:6',
  '1 Nephi 13:7',
  '1 Nephi 13:8',
  '1 Nephi 13:9',
  '1 Nephi 13:10',
  '1 Nephi 13:11',
  '1 Nephi 13:12',
  '1 Nephi 13:13',
  '1 Nephi 13:14',
  '1 Nephi 13:15',
  '1 Nephi 13:16',
  '1 Nephi 13:17',
  '1 Nephi 13:18',
  '1 Nephi 13:19',
  '1 Nephi 13:20',
  '1 Nephi 13:21',
  '1 Nephi 13:22',
  '1 Nephi 13:23',
  '1 Nephi 13:24',
  '1 Nephi 13:25',
  '1 Nephi 13:26',
  '1 Nephi 13:27',
  '1 Nephi 13:28',
  '1 Nephi 13:29',
  '1 Nephi 13:30',
  '1 Nephi 13:31',
  '1 Nephi 13:32',
  '1 Nephi 13:33',
  '1 Nephi 13:34',
  '1 Nephi 13:35',
  '1 Nephi 13:36',
  '1 Nephi 13:37',
  '1 Nephi 13:38',
  '1 Nephi 13:39',
  '1 Nephi 13:40',
  '1 Nephi 13:41',
  '1 Nephi 13:42',
  '1 Nephi 14:1',
  '1 Nephi 14:2',
  '1 Nephi 14:3',
  '1 Nephi 14:4',
  '1 Nephi 14:5',
  '1 Nephi 14:6',
  '1 Nephi 14:7',
  '1 Nephi 14:8',
  '1 Nephi 14:9',
  '1 Nephi 14:10',
  '1 Nephi 14:11',
  '1 Nephi 14:12',
  '1 Nephi 14:13',
  '1 Nephi 14:14',
  '1 Nephi 14:15',
  '1 Nephi 14:16',
  '1 Nephi 14:17',
  '1 Nephi 14:18',
  '1 Nephi 14:19',
  '1 Nephi 14:20',
  '1 Nephi 14:21',
  '1 Nephi 14:22',
  '1 Nephi 14:23',
  '1 Nephi 14:24',
  '1 Nephi 14:25',
  '1 Nephi 14:26',
  '1 Nephi 14:27',
  '1 Nephi 14:28',
  '1 Nephi 14:29',
  '1 Nephi 14:30',
  '1 Nephi 15:1',
  '1 Nephi 15:2',
  '1 Nephi 15:3',
  '1 Nephi 15:4',
  '1 Nephi 15:5',
  '1 Nephi 15:6',
  '1 Nephi 15:7',
  '1 Nephi 15:8',
  '1 Nephi 15:9',
  '1 Nephi 15:10',
  '1 Nephi 15:11',
  '1 Nephi 15:12',
  '1 Nephi 15:13',
  '1 Nephi 15:14',
  '1 Nephi 15:15',
  '1 Nephi 15:16',
  '1 Nephi 15:17',
  '1 Nephi 15:18',
  '1 Nephi 15:19',
  '1 Nephi 15:20',
  '1 Nephi 15:21',
  '1 Nephi 15:22',
  '1 Nephi 15:23',
  '1 Nephi 15:24',
  '1 Nephi 15:25',
  '1 Nephi 15:26',
  '1 Nephi 15:27',
  '1 Nephi 15:28',
  '1 Nephi 15:29',
  '1 Nephi 15:30',
  '1 Nephi 15:31',
  '1 Nephi 15:32',
  '1 Nephi 15:33',
  '1 Nephi 15:34',
  '1 Nephi 15:35',
  '1 Nephi 15:36',
  '1 Nephi 16:1',
  '1 Nephi 16:2',
  '1 Nephi 16:3',
  '1 Nephi 16:4',
  '1 Nephi 16:5',
  '1 Nephi 16:6',
  '1 Nephi 16:7',
  '1 Nephi 16:8',
  '1 Nephi 16:9',
  '1 Nephi 16:10',
  '1 Nephi 16:11',
  '1 Nephi 16:12',
  '1 Nephi 16:13',
  '1 Nephi 16:14',
  '1 Nephi 16:15',
  '1 Nephi 16:16',
  '1 Nephi 16:17',
  '1 Nephi 16:18',
  '1 Nephi 16:19',
  '1 Nephi 16:20',
  '1 Nephi 16:21',
  '1 Nephi 16:22',
  '1 Nephi 16:23',
  '1 Nephi 16:24',
  '1 Nephi 16:25',
  '1 Nephi 16:26',
  '1 Nephi 16:27',
  '1 Nephi 16:28',
  '1 Nephi 16:29',
  '1 Nephi 16:30',
  '1 Nephi 16:31',
  '1 Nephi 16:32',
  '1 Nephi 16:33',
  '1 Nephi 16:34',
  '1 Nephi 16:35',
  '1 Nephi 16:36',
  '1 Nephi 16:37',
  '1 Nephi 16:38',
  '1 Nephi 16:39',
  '1 Nephi 17:1',
  '1 Nephi 17:2',
  '1 Nephi 17:3',
  '1 Nephi 17:4',
  '1 Nephi 17:5',
  '1 Nephi 17:6',
  '1 Nephi 17:7',
  '1 Nephi 17:8',
  '1 Nephi 17:9',
  '1 Nephi 17:10',
  '1 Nephi 17:11',
  '1 Nephi 17:12',
  '1 Nephi 17:13',
  '1 Nephi 17:14',
  '1 Nephi 17:15',
  '1 Nephi 17:16',
  '1 Nephi 17:17',
  '1 Nephi 17:18',
  '1 Nephi 17:19',
  '1 Nephi 17:20',
  '1 Nephi 17:21',
  '1 Nephi 17:22',
  '1 Nephi 17:23',
  '1 Nephi 17:24',
  '1 Nephi 17:25',
  '1 Nephi 17:26',
  '1 Nephi 17:27',
  '1 Nephi 17:28',
  '1 Nephi 17:29',
  '1 Nephi 17:30',
  '1 Nephi 17:31',
  '1 Nephi 17:32',
  '1 Nephi 17:33',
  '1 Nephi 17:34',
  '1 Nephi 17:35',
  '1 Nephi 17:36',
  '1 Nephi 17:37',
  '1 Nephi 17:38',
  '1 Nephi 17:39',
  '1 Nephi 17:40',
  '1 Nephi 17:41',
  '1 Nephi 17:42',
  '1 Nephi 17:43',
  '1 Nephi 17:44',
  '1 Nephi 17:45',
  '1 Nephi 17:46',
  '1 Nephi 17:47',
  '1 Nephi 17:48',
  '1 Nephi 17:49',
  '1 Nephi 17:50',
  '1 Nephi 17:51',
  '1 Nephi 17:52',
  '1 Nephi 17:53',
  '1 Nephi 17:54',
  '1 Nephi 17:55',
  '1 Nephi 18:1',
  '1 Nephi 18:2',
  '1 Nephi 18:3',
  '1 Nephi 18:4',
  '1 Nephi 18:5',
  '1 Nephi 18:6',
  '1 Nephi 18:7',
  '1 Nephi 18:8',
  '1 Nephi 18:9',
  '1 Nephi 18:10',
  '1 Nephi 18:11',
  '1 Nephi 18:12',
  '1 Nephi 18:13',
  '1 Nephi 18:14',
  '1 Nephi 18:15',
  '1 Nephi 18:16',
  '1 Nephi 18:17',
  '1 Nephi 18:18',
  '1 Nephi 18:19',
  '1 Nephi 18:20',
  '1 Nephi 18:21',
  '1 Nephi 18:22',
  '1 Nephi 18:23',
  '1 Nephi 18:24',
  '1 Nephi 18:25',
  '1 Nephi 19:1',
  '1 Nephi 19:2',
  '1 Nephi 19:3',
  '1 Nephi 19:4',
  '1 Nephi 19:5',
  '1 Nephi 19:6',
  '1 Nephi 19:7',
  '1 Nephi 19:8',
  '1 Nephi 19:9',
  '1 Nephi 19:10',
  '1 Nephi 19:11',
  '1 Nephi 19:12',
  '1 Nephi 19:13',
  '1 Nephi 19:14',
  '1 Nephi 19:15',
  '1 Nephi 19:16',
  '1 Nephi 19:17',
  '1 Nephi 19:18',
  '1 Nephi 19:19',
  '1 Nephi 19:20',
  '1 Nephi 19:21',
  '1 Nephi 19:22',
  '1 Nephi 19:23',
  '1 Nephi 19:24',
  '1 Nephi 20:1',
  '1 Nephi 20:2',
  '1 Nephi 20:3',
  '1 Nephi 20:4',
  '1 Nephi 20:5',
  '1 Nephi 20:6',
  '1 Nephi 20:7',
  '1 Nephi 20:8',
  '1 Nephi 20:9',
  '1 Nephi 20:10',
  '1 Nephi 20:11',
  '1 Nephi 20:12',
  '1 Nephi 20:13',
  '1 Nephi 20:14',
  '1 Nephi 20:15',
  '1 Nephi 20:16',
  '1 Nephi 20:17',
  '1 Nephi 20:18',
  '1 Nephi 20:19',
  '1 Nephi 20:20',
  '1 Nephi 20:21',
  '1 Nephi 20:22',
  '1 Nephi 21:1',
  '1 Nephi 21:2',
  '1 Nephi 21:3',
  '1 Nephi 21:4',
  '1 Nephi 21:5',
  '1 Nephi 21:6',
  '1 Nephi 21:7',
  '1 Nephi 21:8',
  '1 Nephi 21:9',
  '1 Nephi 21:10',
  '1 Nephi 21:11',
  '1 Nephi 21:12',
  '1 Nephi 21:13',
  '1 Nephi 21:14',
  '1 Nephi 21:15',
  '1 Nephi 21:16',
  '1 Nephi 21:17',
  '1 Nephi 21:18',
  '1 Nephi 21:19',
  '1 Nephi 21:20',
  '1 Nephi 21:21',
  '1 Nephi 21:22',
  '1 Nephi 21:23',
  '1 Nephi 21:24',
  '1 Nephi 21:25',
  '1 Nephi 21:26',
  '1 Nephi 22:1',
  '1 Nephi 22:2',
  '1 Nephi 22:3',
  '1 Nephi 22:4',
  '1 Nephi 22:5',
  '1 Nephi 22:6',
  '1 Nephi 22:7',
  '1 Nephi 22:8',
  '1 Nephi 22:9',
  '1 Nephi 22:10',
  '1 Nephi 22:11',
  '1 Nephi 22:12',
  '1 Nephi 22:13',
  '1 Nephi 22:14',
  '1 Nephi 22:15',
  '1 Nephi 22:16',
  '1 Nephi 22:17',
  '1 Nephi 22:18',
  '1 Nephi 22:19',
  '1 Nephi 22:20',
  '1 Nephi 22:21',
  '1 Nephi 22:22',
  '1 Nephi 22:23',
  '1 Nephi 22:24',
  '1 Nephi 22:25',
  '1 Nephi 22:26',
  '1 Nephi 22:27',
  '1 Nephi 22:28',
  '1 Nephi 22:29',
  '1 Nephi 22:30',
  '1 Nephi 22:31',
  '2 Nephi 1:1',
  '2 Nephi 1:2',
  '2 Nephi 1:3',
  '2 Nephi 1:4',
  '2 Nephi 1:5',
  '2 Nephi 1:6',
  '2 Nephi 1:7',
  '2 Nephi 1:8',
  '2 Nephi 1:9',
  '2 Nephi 1:10',
  '2 Nephi 1:11',
  '2 Nephi 1:12',
  '2 Nephi 1:13',
  '2 Nephi 1:14',
  '2 Nephi 1:15',
  '2 Nephi 1:16',
  '2 Nephi 1:17',
  '2 Nephi 1:18',
  '2 Nephi 1:19',
  '2 Nephi 1:20',
  '2 Nephi 1:21',
  '2 Nephi 1:22',
  '2 Nephi 1:23',
  '2 Nephi 1:24',
  '2 Nephi 1:25',
  '2 Nephi 1:26',
  '2 Nephi 1:27',
  '2 Nephi 1:28',
  '2 Nephi 1:29',
  '2 Nephi 1:30',
  '2 Nephi 1:31',
  '2 Nephi 1:32',
  '2 Nephi 2:1',
  '2 Nephi 2:2',
  '2 Nephi 2:3',
  '2 Nephi 2:4',
  '2 Nephi 2:5',
  '2 Nephi 2:6',
  '2 Nephi 2:7',
  '2 Nephi 2:8',
  '2 Nephi 2:9',
  '2 Nephi 2:10',
  '2 Nephi 2:11',
  '2 Nephi 2:12',
  '2 Nephi 2:13',
  '2 Nephi 2:14',
  '2 Nephi 2:15',
  '2 Nephi 2:16',
  '2 Nephi 2:17',
  '2 Nephi 2:18',
  '2 Nephi 2:19',
  '2 Nephi 2:20',
  '2 Nephi 2:21',
  '2 Nephi 2:22',
  '2 Nephi 2:23',
  '2 Nephi 2:24',
  '2 Nephi 2:25',
  '2 Nephi 2:26',
  '2 Nephi 2:27',
  '2 Nephi 2:28',
  '2 Nephi 2:29',
  '2 Nephi 2:30',
  '2 Nephi 3:1',
  '2 Nephi 3:2',
  '2 Nephi 3:3',
  '2 Nephi 3:4',
  '2 Nephi 3:5',
  '2 Nephi 3:6',
  '2 Nephi 3:7',
  '2 Nephi 3:8',
  '2 Nephi 3:9',
  '2 Nephi 3:10',
  '2 Nephi 3:11',
  '2 Nephi 3:12',
  '2 Nephi 3:13',
  '2 Nephi 3:14',
  '2 Nephi 3:15',
  '2 Nephi 3:16',
  '2 Nephi 3:17',
  '2 Nephi 3:18',
  '2 Nephi 3:19',
  '2 Nephi 3:20',
  '2 Nephi 3:21',
  '2 Nephi 3:22',
  '2 Nephi 3:23',
  '2 Nephi 3:24',
  '2 Nephi 3:25',
  '2 Nephi 4:1',
  '2 Nephi 4:2',
  '2 Nephi 4:3',
  '2 Nephi 4:4',
  '2 Nephi 4:5',
  '2 Nephi 4:6',
  '2 Nephi 4:7',
  '2 Nephi 4:8',
  '2 Nephi 4:9',
  '2 Nephi 4:10',
  '2 Nephi 4:11',
  '2 Nephi 4:12',
  '2 Nephi 4:13',
  '2 Nephi 4:14',
  '2 Nephi 4:15',
  '2 Nephi 4:16',
  '2 Nephi 4:17',
  '2 Nephi 4:18',
  '2 Nephi 4:19',
  '2 Nephi 4:20',
  '2 Nephi 4:21',
  '2 Nephi 4:22',
  '2 Nephi 4:23',
  '2 Nephi 4:24',
  '2 Nephi 4:25',
  '2 Nephi 4:26',
  '2 Nephi 4:27',
  '2 Nephi 4:28',
  '2 Nephi 4:29',
  '2 Nephi 4:30',
  '2 Nephi 4:31',
  '2 Nephi 4:32',
  '2 Nephi 4:33',
  '2 Nephi 4:34',
  '2 Nephi 4:35',
  '2 Nephi 5:1',
  '2 Nephi 5:2',
  '2 Nephi 5:3',
  '2 Nephi 5:4',
  '2 Nephi 5:5',
  '2 Nephi 5:6',
  '2 Nephi 5:7',
  '2 Nephi 5:8',
  '2 Nephi 5:9',
  '2 Nephi 5:10',
  '2 Nephi 5:11',
  '2 Nephi 5:12',
  '2 Nephi 5:13',
  '2 Nephi 5:14',
  '2 Nephi 5:15',
  '2 Nephi 5:16',
  '2 Nephi 5:17',
  '2 Nephi 5:18',
  '2 Nephi 5:19',
  '2 Nephi 5:20',
  '2 Nephi 5:21',
  '2 Nephi 5:22',
  '2 Nephi 5:23',
  '2 Nephi 5:24',
  '2 Nephi 5:25',
  '2 Nephi 5:26',
  '2 Nephi 5:27',
  '2 Nephi 5:28',
  '2 Nephi 5:29',
  '2 Nephi 5:30',
  '2 Nephi 5:31',
  '2 Nephi 5:32',
  '2 Nephi 5:33',
  '2 Nephi 5:34',
  '2 Nephi 6:1',
  '2 Nephi 6:2',
  '2 Nephi 6:3',
  '2 Nephi 6:4',
  '2 Nephi 6:5',
  '2 Nephi 6:6',
  '2 Nephi 6:7',
  '2 Nephi 6:8',
  '2 Nephi 6:9',
  '2 Nephi 6:10',
  '2 Nephi 6:11',
  '2 Nephi 6:12',
  '2 Nephi 6:13',
  '2 Nephi 6:14',
  '2 Nephi 6:15',
  '2 Nephi 6:16',
  '2 Nephi 6:17',
  '2 Nephi 6:18',
  '2 Nephi 7:1',
  '2 Nephi 7:2',
  '2 Nephi 7:3',
  '2 Nephi 7:4',
  '2 Nephi 7:5',
  '2 Nephi 7:6',
  '2 Nephi 7:7',
  '2 Nephi 7:8',
  '2 Nephi 7:9',
  '2 Nephi 7:10',
  '2 Nephi 7:11',
  '2 Nephi 8:1',
  '2 Nephi 8:2',
  '2 Nephi 8:3',
  '2 Nephi 8:4',
  '2 Nephi 8:5',
  '2 Nephi 8:6',
  '2 Nephi 8:7',
  '2 Nephi 8:8',
  '2 Nephi 8:9',
  '2 Nephi 8:10',
  '2 Nephi 8:11',
  '2 Nephi 8:12',
  '2 Nephi 8:13',
  '2 Nephi 8:14',
  '2 Nephi 8:15',
  '2 Nephi 8:16',
  '2 Nephi 8:17',
  '2 Nephi 8:18',
  '2 Nephi 8:19',
  '2 Nephi 8:20',
  '2 Nephi 8:21',
  '2 Nephi 8:22',
  '2 Nephi 8:23',
  '2 Nephi 8:24',
  '2 Nephi 8:25',
  '2 Nephi 9:1',
  '2 Nephi 9:2',
  '2 Nephi 9:3',
  '2 Nephi 9:4',
  '2 Nephi 9:5',
  '2 Nephi 9:6',
  '2 Nephi 9:7',
  '2 Nephi 9:8',
  '2 Nephi 9:9',
  '2 Nephi 9:10',
  '2 Nephi 9:11',
  '2 Nephi 9:12',
  '2 Nephi 9:13',
  '2 Nephi 9:14',
  '2 Nephi 9:15',
  '2 Nephi 9:16',
  '2 Nephi 9:17',
  '2 Nephi 9:18',
  '2 Nephi 9:19',
  '2 Nephi 9:20',
  '2 Nephi 9:21',
  '2 Nephi 9:22',
  '2 Nephi 9:23',
  '2 Nephi 9:24',
  '2 Nephi 9:25',
  '2 Nephi 9:26',
  '2 Nephi 9:27',
  '2 Nephi 9:28',
  '2 Nephi 9:29',
  '2 Nephi 9:30',
  '2 Nephi 9:31',
  '2 Nephi 9:32',
  '2 Nephi 9:33',
  '2 Nephi 9:34',
  '2 Nephi 9:35',
  '2 Nephi 9:36',
  '2 Nephi 9:37',
  '2 Nephi 9:38',
  '2 Nephi 9:39',
  '2 Nephi 9:40',
  '2 Nephi 9:41',
  '2 Nephi 9:42',
  '2 Nephi 9:43',
  '2 Nephi 9:44',
  '2 Nephi 9:45',
  '2 Nephi 9:46',
  '2 Nephi 9:47',
  '2 Nephi 9:48',
  '2 Nephi 9:49',
  '2 Nephi 9:50',
  '2 Nephi 9:51',
  '2 Nephi 9:52',
  '2 Nephi 9:53',
  '2 Nephi 9:54',
  '2 Nephi 10:1',
  '2 Nephi 10:2',
  '2 Nephi 10:3',
  '2 Nephi 10:4',
  '2 Nephi 10:5',
  '2 Nephi 10:6',
  '2 Nephi 10:7',
  '2 Nephi 10:8',
  '2 Nephi 10:9',
  '2 Nephi 10:10',
  '2 Nephi 10:11',
  '2 Nephi 10:12',
  '2 Nephi 10:13',
  '2 Nephi 10:14',
  '2 Nephi 10:15',
  '2 Nephi 10:16',
  '2 Nephi 10:17',
  '2 Nephi 10:18',
  '2 Nephi 10:19',
  '2 Nephi 10:20',
  '2 Nephi 10:21',
  '2 Nephi 10:22',
  '2 Nephi 10:23',
  '2 Nephi 10:24',
  '2 Nephi 10:25',
  '2 Nephi 11:1',
  '2 Nephi 11:2',
  '2 Nephi 11:3',
  '2 Nephi 11:4',
  '2 Nephi 11:5',
  '2 Nephi 11:6',
  '2 Nephi 11:7',
  '2 Nephi 11:8',
  '2 Nephi 12:1',
  '2 Nephi 12:2',
  '2 Nephi 12:3',
  '2 Nephi 12:4',
  '2 Nephi 12:5',
  '2 Nephi 12:6',
  '2 Nephi 12:7',
  '2 Nephi 12:8',
  '2 Nephi 12:9',
  '2 Nephi 12:10',
  '2 Nephi 12:11',
  '2 Nephi 12:12',
  '2 Nephi 12:13',
  '2 Nephi 12:14',
  '2 Nephi 12:15',
  '2 Nephi 12:16',
  '2 Nephi 12:17',
  '2 Nephi 12:18',
  '2 Nephi 12:19',
  '2 Nephi 12:20',
  '2 Nephi 12:21',
  '2 Nephi 12:22',
  '2 Nephi 13:1',
  '2 Nephi 13:2',
  '2 Nephi 13:3',
  '2 Nephi 13:4',
  '2 Nephi 13:5',
  '2 Nephi 13:6',
  '2 Nephi 13:7',
  '2 Nephi 13:8',
  '2 Nephi 13:9',
  '2 Nephi 13:10',
  '2 Nephi 13:11',
  '2 Nephi 13:12',
  '2 Nephi 13:13',
  '2 Nephi 13:14',
  '2 Nephi 13:15',
  '2 Nephi 13:16',
  '2 Nephi 13:17',
  '2 Nephi 13:18',
  '2 Nephi 13:19',
  '2 Nephi 13:20',
  '2 Nephi 13:21',
  '2 Nephi 13:22',
  '2 Nephi 13:23',
  '2 Nephi 13:24',
  '2 Nephi 13:25',
  '2 Nephi 13:26',
  '2 Nephi 14:1',
  '2 Nephi 14:2',
  '2 Nephi 14:3',
  '2 Nephi 14:4',
  '2 Nephi 14:5',
  '2 Nephi 14:6',
  '2 Nephi 15:1',
  '2 Nephi 15:2',
  '2 Nephi 15:3',
  '2 Nephi 15:4',
  '2 Nephi 15:5',
  '2 Nephi 15:6',
  '2 Nephi 15:7',
  '2 Nephi 15:8',
  '2 Nephi 15:9',
  '2 Nephi 15:10',
  '2 Nephi 15:11',
  '2 Nephi 15:12',
  '2 Nephi 15:13',
  '2 Nephi 15:14',
  '2 Nephi 15:15',
  '2 Nephi 15:16',
  '2 Nephi 15:17',
  '2 Nephi 15:18',
  '2 Nephi 15:19',
  '2 Nephi 15:20',
  '2 Nephi 15:21',
  '2 Nephi 15:22',
  '2 Nephi 15:23',
  '2 Nephi 15:24',
  '2 Nephi 15:25',
  '2 Nephi 15:26',
  '2 Nephi 15:27',
  '2 Nephi 15:28',
  '2 Nephi 15:29',
  '2 Nephi 15:30',
  '2 Nephi 16:1',
  '2 Nephi 16:2',
  '2 Nephi 16:3',
  '2 Nephi 16:4',
  '2 Nephi 16:5',
  '2 Nephi 16:6',
  '2 Nephi 16:7',
  '2 Nephi 16:8',
  '2 Nephi 16:9',
  '2 Nephi 16:10',
  '2 Nephi 16:11',
  '2 Nephi 16:12',
  '2 Nephi 16:13',
  '2 Nephi 17:1',
  '2 Nephi 17:2',
  '2 Nephi 17:3',
  '2 Nephi 17:4',
  '2 Nephi 17:5',
  '2 Nephi 17:6',
  '2 Nephi 17:7',
  '2 Nephi 17:8',
  '2 Nephi 17:9',
  '2 Nephi 17:10',
  '2 Nephi 17:11',
  '2 Nephi 17:12',
  '2 Nephi 17:13',
  '2 Nephi 17:14',
  '2 Nephi 17:15',
  '2 Nephi 17:16',
  '2 Nephi 17:17',
  '2 Nephi 17:18',
  '2 Nephi 17:19',
  '2 Nephi 17:20',
  '2 Nephi 17:21',
  '2 Nephi 17:22',
  '2 Nephi 17:23',
  '2 Nephi 17:24',
  '2 Nephi 17:25',
  '2 Nephi 18:1',
  '2 Nephi 18:2',
  '2 Nephi 18:3',
  '2 Nephi 18:4',
  '2 Nephi 18:5',
  '2 Nephi 18:6',
  '2 Nephi 18:7',
  '2 Nephi 18:8',
  '2 Nephi 18:9',
  '2 Nephi 18:10',
  '2 Nephi 18:11',
  '2 Nephi 18:12',
  '2 Nephi 18:13',
  '2 Nephi 18:14',
  '2 Nephi 18:15',
  '2 Nephi 18:16',
  '2 Nephi 18:17',
  '2 Nephi 18:18',
  '2 Nephi 18:19',
  '2 Nephi 18:20',
  '2 Nephi 18:21',
  '2 Nephi 18:22',
  '2 Nephi 19:1',
  '2 Nephi 19:2',
  '2 Nephi 19:3',
  '2 Nephi 19:4',
  '2 Nephi 19:5',
  '2 Nephi 19:6',
  '2 Nephi 19:7',
  '2 Nephi 19:8',
  '2 Nephi 19:9',
  '2 Nephi 19:10',
  '2 Nephi 19:11',
  '2 Nephi 19:12',
  '2 Nephi 19:13',
  '2 Nephi 19:14',
  '2 Nephi 19:15',
  '2 Nephi 19:16',
  '2 Nephi 19:17',
  '2 Nephi 19:18',
  '2 Nephi 19:19',
  '2 Nephi 19:20',
  '2 Nephi 19:21',
  '2 Nephi 20:1',
  '2 Nephi 20:2',
  '2 Nephi 20:3',
  '2 Nephi 20:4',
  '2 Nephi 20:5',
  '2 Nephi 20:6',
  '2 Nephi 20:7',
  '2 Nephi 20:8',
  '2 Nephi 20:9',
  '2 Nephi 20:10',
  '2 Nephi 20:11',
  '2 Nephi 20:12',
  '2 Nephi 20:13',
  '2 Nephi 20:14',
  '2 Nephi 20:15',
  '2 Nephi 20:16',
  '2 Nephi 20:17',
  '2 Nephi 20:18',
  '2 Nephi 20:19',
  '2 Nephi 20:20',
  '2 Nephi 20:21',
  '2 Nephi 20:22',
  '2 Nephi 20:23',
  '2 Nephi 20:24',
  '2 Nephi 20:25',
  '2 Nephi 20:26',
  '2 Nephi 20:27',
  '2 Nephi 20:28',
  '2 Nephi 20:29',
  '2 Nephi 20:30',
  '2 Nephi 20:31',
  '2 Nephi 20:32',
  '2 Nephi 20:33',
  '2 Nephi 20:34',
  '2 Nephi 21:1',
  '2 Nephi 21:2',
  '2 Nephi 21:3',
  '2 Nephi 21:4',
  '2 Nephi 21:5',
  '2 Nephi 21:6',
  '2 Nephi 21:7',
  '2 Nephi 21:8',
  '2 Nephi 21:9',
  '2 Nephi 21:10',
  '2 Nephi 21:11',
  '2 Nephi 21:12',
  '2 Nephi 21:13',
  '2 Nephi 21:14',
  '2 Nephi 21:15',
  '2 Nephi 21:16',
  '2 Nephi 22:1',
  '2 Nephi 22:2',
  '2 Nephi 22:3',
  '2 Nephi 22:4',
  '2 Nephi 22:5',
  '2 Nephi 22:6',
  '2 Nephi 23:1',
  '2 Nephi 23:2',
  '2 Nephi 23:3',
  '2 Nephi 23:4',
  '2 Nephi 23:5',
  '2 Nephi 23:6',
  '2 Nephi 23:7',
  '2 Nephi 23:8',
  '2 Nephi 23:9',
  '2 Nephi 23:10',
  '2 Nephi 23:11',
  '2 Nephi 23:12',
  '2 Nephi 23:13',
  '2 Nephi 23:14',
  '2 Nephi 23:15',
  '2 Nephi 23:16',
  '2 Nephi 23:17',
  '2 Nephi 23:18',
  '2 Nephi 23:19',
  '2 Nephi 23:20',
  '2 Nephi 23:21',
  '2 Nephi 23:22',
  '2 Nephi 24:1',
  '2 Nephi 24:2',
  '2 Nephi 24:3',
  '2 Nephi 24:4',
  '2 Nephi 24:5',
  '2 Nephi 24:6',
  '2 Nephi 24:7',
  '2 Nephi 24:8',
  '2 Nephi 24:9',
  '2 Nephi 24:10',
  '2 Nephi 24:11',
  '2 Nephi 24:12',
  '2 Nephi 24:13',
  '2 Nephi 24:14',
  '2 Nephi 24:15',
  '2 Nephi 24:16',
  '2 Nephi 24:17',
  '2 Nephi 24:18',
  '2 Nephi 24:19',
  '2 Nephi 24:20',
  '2 Nephi 24:21',
  '2 Nephi 24:22',
  '2 Nephi 24:23',
  '2 Nephi 24:24',
  '2 Nephi 24:25',
  '2 Nephi 24:26',
  '2 Nephi 24:27',
  '2 Nephi 24:28',
  '2 Nephi 24:29',
  '2 Nephi 24:30',
  '2 Nephi 24:31',
  '2 Nephi 24:32',
  '2 Nephi 25:1',
  '2 Nephi 25:2',
  '2 Nephi 25:3',
  '2 Nephi 25:4',
  '2 Nephi 25:5',
  '2 Nephi 25:6',
  '2 Nephi 25:7',
  '2 Nephi 25:8',
  '2 Nephi 25:9',
  '2 Nephi 25:10',
  '2 Nephi 25:11',
  '2 Nephi 25:12',
  '2 Nephi 25:13',
  '2 Nephi 25:14',
  '2 Nephi 25:15',
  '2 Nephi 25:16',
  '2 Nephi 25:17',
  '2 Nephi 25:18',
  '2 Nephi 25:19',
  '2 Nephi 25:20',
  '2 Nephi 25:21',
  '2 Nephi 25:22',
  '2 Nephi 25:23',
  '2 Nephi 25:24',
  '2 Nephi 25:25',
  '2 Nephi 25:26',
  '2 Nephi 25:27',
  '2 Nephi 25:28',
  '2 Nephi 25:29',
  '2 Nephi 25:30',
  '2 Nephi 26:1',
  '2 Nephi 26:2',
  '2 Nephi 26:3',
  '2 Nephi 26:4',
  '2 Nephi 26:5',
  '2 Nephi 26:6',
  '2 Nephi 26:7',
  '2 Nephi 26:8',
  '2 Nephi 26:9',
  '2 Nephi 26:10',
  '2 Nephi 26:11',
  '2 Nephi 26:12',
  '2 Nephi 26:13',
  '2 Nephi 26:14',
  '2 Nephi 26:15',
  '2 Nephi 26:16',
  '2 Nephi 26:17',
  '2 Nephi 26:18',
  '2 Nephi 26:19',
  '2 Nephi 26:20',
  '2 Nephi 26:21',
  '2 Nephi 26:22',
  '2 Nephi 26:23',
  '2 Nephi 26:24',
  '2 Nephi 26:25',
  '2 Nephi 26:26',
  '2 Nephi 26:27',
  '2 Nephi 26:28',
  '2 Nephi 26:29',
  '2 Nephi 26:30',
  '2 Nephi 26:31',
  '2 Nephi 26:32',
  '2 Nephi 26:33',
  '2 Nephi 27:1',
  '2 Nephi 27:2',
  '2 Nephi 27:3',
  '2 Nephi 27:4',
  '2 Nephi 27:5',
  '2 Nephi 27:6',
  '2 Nephi 27:7',
  '2 Nephi 27:8',
  '2 Nephi 27:9',
  '2 Nephi 27:10',
  '2 Nephi 27:11',
  '2 Nephi 27:12',
  '2 Nephi 27:13',
  '2 Nephi 27:14',
  '2 Nephi 27:15',
  '2 Nephi 27:16',
  '2 Nephi 27:17',
  '2 Nephi 27:18',
  '2 Nephi 27:19',
  '2 Nephi 27:20',
  '2 Nephi 27:21',
  '2 Nephi 27:22',
  '2 Nephi 27:23',
  '2 Nephi 27:24',
  '2 Nephi 27:25',
  '2 Nephi 27:26',
  '2 Nephi 27:27',
  '2 Nephi 27:28',
  '2 Nephi 27:29',
  '2 Nephi 27:30',
  '2 Nephi 27:31',
  '2 Nephi 27:32',
  '2 Nephi 27:33',
  '2 Nephi 27:34',
  '2 Nephi 27:35',
  '2 Nephi 28:1',
  '2 Nephi 28:2',
  '2 Nephi 28:3',
  '2 Nephi 28:4',
  '2 Nephi 28:5',
  '2 Nephi 28:6',
  '2 Nephi 28:7',
  '2 Nephi 28:8',
  '2 Nephi 28:9',
  '2 Nephi 28:10',
  '2 Nephi 28:11',
  '2 Nephi 28:12',
  '2 Nephi 28:13',
  '2 Nephi 28:14',
  '2 Nephi 28:15',
  '2 Nephi 28:16',
  '2 Nephi 28:17',
  '2 Nephi 28:18',
  '2 Nephi 28:19',
  '2 Nephi 28:20',
  '2 Nephi 28:21',
  '2 Nephi 28:22',
  '2 Nephi 28:23',
  '2 Nephi 28:24',
  '2 Nephi 28:25',
  '2 Nephi 28:26',
  '2 Nephi 28:27',
  '2 Nephi 28:28',
  '2 Nephi 28:29',
  '2 Nephi 28:30',
  '2 Nephi 28:31',
  '2 Nephi 28:32',
  '2 Nephi 29:1',
  '2 Nephi 29:2',
  '2 Nephi 29:3',
  '2 Nephi 29:4',
  '2 Nephi 29:5',
  '2 Nephi 29:6',
  '2 Nephi 29:7',
  '2 Nephi 29:8',
  '2 Nephi 29:9',
  '2 Nephi 29:10',
  '2 Nephi 29:11',
  '2 Nephi 29:12',
  '2 Nephi 29:13',
  '2 Nephi 29:14',
  '2 Nephi 30:1',
  '2 Nephi 30:2',
  '2 Nephi 30:3',
  '2 Nephi 30:4',
  '2 Nephi 30:5',
  '2 Nephi 30:6',
  '2 Nephi 30:7',
  '2 Nephi 30:8',
  '2 Nephi 30:9',
  '2 Nephi 30:10',
  '2 Nephi 30:11',
  '2 Nephi 30:12',
  '2 Nephi 30:13',
  '2 Nephi 30:14',
  '2 Nephi 30:15',
  '2 Nephi 30:16',
  '2 Nephi 30:17',
  '2 Nephi 30:18',
  '2 Nephi 31:1',
  '2 Nephi 31:2',
  '2 Nephi 31:3',
  '2 Nephi 31:4',
  '2 Nephi 31:5',
  '2 Nephi 31:6',
  '2 Nephi 31:7',
  '2 Nephi 31:8',
  '2 Nephi 31:9',
  '2 Nephi 31:10',
  '2 Nephi 31:11',
  '2 Nephi 31:12',
  '2 Nephi 31:13',
  '2 Nephi 31:14',
  '2 Nephi 31:15',
  '2 Nephi 31:16',
  '2 Nephi 31:17',
  '2 Nephi 31:18',
  '2 Nephi 31:19',
  '2 Nephi 31:20',
  '2 Nephi 31:21',
  '2 Nephi 32:1',
  '2 Nephi 32:2',
  '2 Nephi 32:3',
  '2 Nephi 32:4',
  '2 Nephi 32:5',
  '2 Nephi 32:6',
  '2 Nephi 32:7',
  '2 Nephi 32:8',
  '2 Nephi 32:9',
  '2 Nephi 33:1',
  '2 Nephi 33:2',
  '2 Nephi 33:3',
  '2 Nephi 33:4',
  '2 Nephi 33:5',
  '2 Nephi 33:6',
  '2 Nephi 33:7',
  '2 Nephi 33:8',
  '2 Nephi 33:9',
  '2 Nephi 33:10',
  '2 Nephi 33:11',
  '2 Nephi 33:12',
  '2 Nephi 33:13',
  '2 Nephi 33:14',
  '2 Nephi 33:15',
  'Jacob 1:1',
  'Jacob 1:2',
  'Jacob 1:3',
  'Jacob 1:4',
  'Jacob 1:5',
  'Jacob 1:6',
  'Jacob 1:7',
  'Jacob 1:8',
  'Jacob 1:9',
  'Jacob 1:10',
  'Jacob 1:11',
  'Jacob 1:12',
  'Jacob 1:13',
  'Jacob 1:14',
  'Jacob 1:15',
  'Jacob 1:16',
  'Jacob 1:17',
  'Jacob 1:18',
  'Jacob 1:19',
  'Jacob 2:1',
  'Jacob 2:2',
  'Jacob 2:3',
  'Jacob 2:4',
  'Jacob 2:5',
  'Jacob 2:6',
  'Jacob 2:7',
  'Jacob 2:8',
  'Jacob 2:9',
  'Jacob 2:10',
  'Jacob 2:11',
  'Jacob 2:12',
  'Jacob 2:13',
  'Jacob 2:14',
  'Jacob 2:15',
  'Jacob 2:16',
  'Jacob 2:17',
  'Jacob 2:18',
  'Jacob 2:19',
  'Jacob 2:20',
  'Jacob 2:21',
  'Jacob 2:22',
  'Jacob 2:23',
  'Jacob 2:24',
  'Jacob 2:25',
  'Jacob 2:26',
  'Jacob 2:27',
  'Jacob 2:28',
  'Jacob 2:29',
  'Jacob 2:30',
  'Jacob 2:31',
  'Jacob 2:32',
  'Jacob 2:33',
  'Jacob 2:34',
  'Jacob 2:35',
  'Jacob 3:1',
  'Jacob 3:2',
  'Jacob 3:3',
  'Jacob 3:4',
  'Jacob 3:5',
  'Jacob 3:6',
  'Jacob 3:7',
  'Jacob 3:8',
  'Jacob 3:9',
  'Jacob 3:10',
  'Jacob 3:11',
  'Jacob 3:12',
  'Jacob 3:13',
  'Jacob 3:14',
  'Jacob 4:1',
  'Jacob 4:2',
  'Jacob 4:3',
  'Jacob 4:4',
  'Jacob 4:5',
  'Jacob 4:6',
  'Jacob 4:7',
  'Jacob 4:8',
  'Jacob 4:9',
  'Jacob 4:10',
  'Jacob 4:11',
  'Jacob 4:12',
  'Jacob 4:13',
  'Jacob 4:14',
  'Jacob 4:15',
  'Jacob 4:16',
  'Jacob 4:17',
  'Jacob 4:18',
  'Jacob 5:1',
  'Jacob 5:2',
  'Jacob 5:3',
  'Jacob 5:4',
  'Jacob 5:5',
  'Jacob 5:6',
  'Jacob 5:7',
  'Jacob 5:8',
  'Jacob 5:9',
  'Jacob 5:10',
  'Jacob 5:11',
  'Jacob 5:12',
  'Jacob 5:13',
  'Jacob 5:14',
  'Jacob 5:15',
  'Jacob 5:16',
  'Jacob 5:17',
  'Jacob 5:18',
  'Jacob 5:19',
  'Jacob 5:20',
  'Jacob 5:21',
  'Jacob 5:22',
  'Jacob 5:23',
  'Jacob 5:24',
  'Jacob 5:25',
  'Jacob 5:26',
  'Jacob 5:27',
  'Jacob 5:28',
  'Jacob 5:29',
  'Jacob 5:30',
  'Jacob 5:31',
  'Jacob 5:32',
  'Jacob 5:33',
  'Jacob 5:34',
  'Jacob 5:35',
  'Jacob 5:36',
  'Jacob 5:37',
  'Jacob 5:38',
  'Jacob 5:39',
  'Jacob 5:40',
  'Jacob 5:41',
  'Jacob 5:42',
  'Jacob 5:43',
  'Jacob 5:44',
  'Jacob 5:45',
  'Jacob 5:46',
  'Jacob 5:47',
  'Jacob 5:48',
  'Jacob 5:49',
  'Jacob 5:50',
  'Jacob 5:51',
  'Jacob 5:52',
  'Jacob 5:53',
  'Jacob 5:54',
  'Jacob 5:55',
  'Jacob 5:56',
  'Jacob 5:57',
  'Jacob 5:58',
  'Jacob 5:59',
  'Jacob 5:60',
  'Jacob 5:61',
  'Jacob 5:62',
  'Jacob 5:63',
  'Jacob 5:64',
  'Jacob 5:65',
  'Jacob 5:66',
  'Jacob 5:67',
  'Jacob 5:68',
  'Jacob 5:69',
  'Jacob 5:70',
  'Jacob 5:71',
  'Jacob 5:72',
  'Jacob 5:73',
  'Jacob 5:74',
  'Jacob 5:75',
  'Jacob 5:76',
  'Jacob 5:77',
  'Jacob 6:1',
  'Jacob 6:2',
  'Jacob 6:3',
  'Jacob 6:4',
  'Jacob 6:5',
  'Jacob 6:6',
  'Jacob 6:7',
  'Jacob 6:8',
  'Jacob 6:9',
  'Jacob 6:10',
  'Jacob 6:11',
  'Jacob 6:12',
  'Jacob 6:13',
  'Jacob 7:1',
  'Jacob 7:2',
  'Jacob 7:3',
  'Jacob 7:4',
  'Jacob 7:5',
  'Jacob 7:6',
  'Jacob 7:7',
  'Jacob 7:8',
  'Jacob 7:9',
  'Jacob 7:10',
  'Jacob 7:11',
  'Jacob 7:12',
  'Jacob 7:13',
  'Jacob 7:14',
  'Jacob 7:15',
  'Jacob 7:16',
  'Jacob 7:17',
  'Jacob 7:18',
  'Jacob 7:19',
  'Jacob 7:20',
  'Jacob 7:21',
  'Jacob 7:22',
  'Jacob 7:23',
  'Jacob 7:24',
  'Jacob 7:25',
  'Jacob 7:26',
  'Jacob 7:27',
  'Enos 1:1',
  'Enos 1:2',
  'Enos 1:3',
  'Enos 1:4',
  'Enos 1:5',
  'Enos 1:6',
  'Enos 1:7',
  'Enos 1:8',
  'Enos 1:9',
  'Enos 1:10',
  'Enos 1:11',
  'Enos 1:12',
  'Enos 1:13',
  'Enos 1:14',
  'Enos 1:15',
  'Enos 1:16',
  'Enos 1:17',
  'Enos 1:18',
  'Enos 1:19',
  'Enos 1:20',
  'Enos 1:21',
  'Enos 1:22',
  'Enos 1:23',
  'Enos 1:24',
  'Enos 1:25',
  'Enos 1:26',
  'Enos 1:27',
  'Jarom 1:1',
  'Jarom 1:2',
  'Jarom 1:3',
  'Jarom 1:4',
  'Jarom 1:5',
  'Jarom 1:6',
  'Jarom 1:7',
  'Jarom 1:8',
  'Jarom 1:9',
  'Jarom 1:10',
  'Jarom 1:11',
  'Jarom 1:12',
  'Jarom 1:13',
  'Jarom 1:14',
  'Jarom 1:15',
  'Omni 1:1',
  'Omni 1:2',
  'Omni 1:3',
  'Omni 1:4',
  'Omni 1:5',
  'Omni 1:6',
  'Omni 1:7',
  'Omni 1:8',
  'Omni 1:9',
  'Omni 1:10',
  'Omni 1:11',
  'Omni 1:12',
  'Omni 1:13',
  'Omni 1:14',
  'Omni 1:15',
  'Omni 1:16',
  'Omni 1:17',
  'Omni 1:18',
  'Omni 1:19',
  'Omni 1:20',
  'Omni 1:21',
  'Omni 1:22',
  'Omni 1:23',
  'Omni 1:24',
  'Omni 1:25',
  'Omni 1:26',
  'Omni 1:27',
  'Omni 1:28',
  'Omni 1:29',
  'Omni 1:30',
  'Words of Mormon 1:1',
  'Words of Mormon 1:2',
  'Words of Mormon 1:3',
  'Words of Mormon 1:4',
  'Words of Mormon 1:5',
  'Words of Mormon 1:6',
  'Words of Mormon 1:7',
  'Words of Mormon 1:8',
  'Words of Mormon 1:9',
  'Words of Mormon 1:10',
  'Words of Mormon 1:11',
  'Words of Mormon 1:12',
  'Words of Mormon 1:13',
  'Words of Mormon 1:14',
  'Words of Mormon 1:15',
  'Words of Mormon 1:16',
  'Words of Mormon 1:17',
  'Words of Mormon 1:18',
  'Mosiah 1:1',
  'Mosiah 1:2',
  'Mosiah 1:3',
  'Mosiah 1:4',
  'Mosiah 1:5',
  'Mosiah 1:6',
  'Mosiah 1:7',
  'Mosiah 1:8',
  'Mosiah 1:9',
  'Mosiah 1:10',
  'Mosiah 1:11',
  'Mosiah 1:12',
  'Mosiah 1:13',
  'Mosiah 1:14',
  'Mosiah 1:15',
  'Mosiah 1:16',
  'Mosiah 1:17',
  'Mosiah 1:18',
  'Mosiah 2:1',
  'Mosiah 2:2',
  'Mosiah 2:3',
  'Mosiah 2:4',
  'Mosiah 2:5',
  'Mosiah 2:6',
  'Mosiah 2:7',
  'Mosiah 2:8',
  'Mosiah 2:9',
  'Mosiah 2:10',
  'Mosiah 2:11',
  'Mosiah 2:12',
  'Mosiah 2:13',
  'Mosiah 2:14',
  'Mosiah 2:15',
  'Mosiah 2:16',
  'Mosiah 2:17',
  'Mosiah 2:18',
  'Mosiah 2:19',
  'Mosiah 2:20',
  'Mosiah 2:21',
  'Mosiah 2:22',
  'Mosiah 2:23',
  'Mosiah 2:24',
  'Mosiah 2:25',
  'Mosiah 2:26',
  'Mosiah 2:27',
  'Mosiah 2:28',
  'Mosiah 2:29',
  'Mosiah 2:30',
  'Mosiah 2:31',
  'Mosiah 2:32',
  'Mosiah 2:33',
  'Mosiah 2:34',
  'Mosiah 2:35',
  'Mosiah 2:36',
  'Mosiah 2:37',
  'Mosiah 2:38',
  'Mosiah 2:39',
  'Mosiah 2:40',
  'Mosiah 2:41',
  'Mosiah 3:1',
  'Mosiah 3:2',
  'Mosiah 3:3',
  'Mosiah 3:4',
  'Mosiah 3:5',
  'Mosiah 3:6',
  'Mosiah 3:7',
  'Mosiah 3:8',
  'Mosiah 3:9',
  'Mosiah 3:10',
  'Mosiah 3:11',
  'Mosiah 3:12',
  'Mosiah 3:13',
  'Mosiah 3:14',
  'Mosiah 3:15',
  'Mosiah 3:16',
  'Mosiah 3:17',
  'Mosiah 3:18',
  'Mosiah 3:19',
  'Mosiah 3:20',
  'Mosiah 3:21',
  'Mosiah 3:22',
  'Mosiah 3:23',
  'Mosiah 3:24',
  'Mosiah 3:25',
  'Mosiah 3:26',
  'Mosiah 3:27',
  'Mosiah 4:1',
  'Mosiah 4:2',
  'Mosiah 4:3',
  'Mosiah 4:4',
  'Mosiah 4:5',
  'Mosiah 4:6',
  'Mosiah 4:7',
  'Mosiah 4:8',
  'Mosiah 4:9',
  'Mosiah 4:10',
  'Mosiah 4:11',
  'Mosiah 4:12',
  'Mosiah 4:13',
  'Mosiah 4:14',
  'Mosiah 4:15',
  'Mosiah 4:16',
  'Mosiah 4:17',
  'Mosiah 4:18',
  'Mosiah 4:19',
  'Mosiah 4:20',
  'Mosiah 4:21',
  'Mosiah 4:22',
  'Mosiah 4:23',
  'Mosiah 4:24',
  'Mosiah 4:25',
  'Mosiah 4:26',
  'Mosiah 4:27',
  'Mosiah 4:28',
  'Mosiah 4:29',
  'Mosiah 4:30',
  'Mosiah 5:1',
  'Mosiah 5:2',
  'Mosiah 5:3',
  'Mosiah 5:4',
  'Mosiah 5:5',
  'Mosiah 5:6',
  'Mosiah 5:7',
  'Mosiah 5:8',
  'Mosiah 5:9',
  'Mosiah 5:10',
  'Mosiah 5:11',
  'Mosiah 5:12',
  'Mosiah 5:13',
  'Mosiah 5:14',
  'Mosiah 5:15',
  'Mosiah 6:1',
  'Mosiah 6:2',
  'Mosiah 6:3',
  'Mosiah 6:4',
  'Mosiah 6:5',
  'Mosiah 6:6',
  'Mosiah 6:7',
  'Mosiah 7:1',
  'Mosiah 7:2',
  'Mosiah 7:3',
  'Mosiah 7:4',
  'Mosiah 7:5',
  'Mosiah 7:6',
  'Mosiah 7:7',
  'Mosiah 7:8',
  'Mosiah 7:9',
  'Mosiah 7:10',
  'Mosiah 7:11',
  'Mosiah 7:12',
  'Mosiah 7:13',
  'Mosiah 7:14',
  'Mosiah 7:15',
  'Mosiah 7:16',
  'Mosiah 7:17',
  'Mosiah 7:18',
  'Mosiah 7:19',
  'Mosiah 7:20',
  'Mosiah 7:21',
  'Mosiah 7:22',
  'Mosiah 7:23',
  'Mosiah 7:24',
  'Mosiah 7:25',
  'Mosiah 7:26',
  'Mosiah 7:27',
  'Mosiah 7:28',
  'Mosiah 7:29',
  'Mosiah 7:30',
  'Mosiah 7:31',
  'Mosiah 7:32',
  'Mosiah 7:33',
  'Mosiah 8:1',
  'Mosiah 8:2',
  'Mosiah 8:3',
  'Mosiah 8:4',
  'Mosiah 8:5',
  'Mosiah 8:6',
  'Mosiah 8:7',
  'Mosiah 8:8',
  'Mosiah 8:9',
  'Mosiah 8:10',
  'Mosiah 8:11',
  'Mosiah 8:12',
  'Mosiah 8:13',
  'Mosiah 8:14',
  'Mosiah 8:15',
  'Mosiah 8:16',
  'Mosiah 8:17',
  'Mosiah 8:18',
  'Mosiah 8:19',
  'Mosiah 8:20',
  'Mosiah 8:21',
  'Mosiah 9:1',
  'Mosiah 9:2',
  'Mosiah 9:3',
  'Mosiah 9:4',
  'Mosiah 9:5',
  'Mosiah 9:6',
  'Mosiah 9:7',
  'Mosiah 9:8',
  'Mosiah 9:9',
  'Mosiah 9:10',
  'Mosiah 9:11',
  'Mosiah 9:12',
  'Mosiah 9:13',
  'Mosiah 9:14',
  'Mosiah 9:15',
  'Mosiah 9:16',
  'Mosiah 9:17',
  'Mosiah 9:18',
  'Mosiah 9:19',
  'Mosiah 10:1',
  'Mosiah 10:2',
  'Mosiah 10:3',
  'Mosiah 10:4',
  'Mosiah 10:5',
  'Mosiah 10:6',
  'Mosiah 10:7',
  'Mosiah 10:8',
  'Mosiah 10:9',
  'Mosiah 10:10',
  'Mosiah 10:11',
  'Mosiah 10:12',
  'Mosiah 10:13',
  'Mosiah 10:14',
  'Mosiah 10:15',
  'Mosiah 10:16',
  'Mosiah 10:17',
  'Mosiah 10:18',
  'Mosiah 10:19',
  'Mosiah 10:20',
  'Mosiah 10:21',
  'Mosiah 10:22',
  'Mosiah 11:1',
  'Mosiah 11:2',
  'Mosiah 11:3',
  'Mosiah 11:4',
  'Mosiah 11:5',
  'Mosiah 11:6',
  'Mosiah 11:7',
  'Mosiah 11:8',
  'Mosiah 11:9',
  'Mosiah 11:10',
  'Mosiah 11:11',
  'Mosiah 11:12',
  'Mosiah 11:13',
  'Mosiah 11:14',
  'Mosiah 11:15',
  'Mosiah 11:16',
  'Mosiah 11:17',
  'Mosiah 11:18',
  'Mosiah 11:19',
  'Mosiah 11:20',
  'Mosiah 11:21',
  'Mosiah 11:22',
  'Mosiah 11:23',
  'Mosiah 11:24',
  'Mosiah 11:25',
  'Mosiah 11:26',
  'Mosiah 11:27',
  'Mosiah 11:28',
  'Mosiah 11:29',
  'Mosiah 12:1',
  'Mosiah 12:2',
  'Mosiah 12:3',
  'Mosiah 12:4',
  'Mosiah 12:5',
  'Mosiah 12:6',
  'Mosiah 12:7',
  'Mosiah 12:8',
  'Mosiah 12:9',
  'Mosiah 12:10',
  'Mosiah 12:11',
  'Mosiah 12:12',
  'Mosiah 12:13',
  'Mosiah 12:14',
  'Mosiah 12:15',
  'Mosiah 12:16',
  'Mosiah 12:17',
  'Mosiah 12:18',
  'Mosiah 12:19',
  'Mosiah 12:20',
  'Mosiah 12:21',
  'Mosiah 12:22',
  'Mosiah 12:23',
  'Mosiah 12:24',
  'Mosiah 12:25',
  'Mosiah 12:26',
  'Mosiah 12:27',
  'Mosiah 12:28',
  'Mosiah 12:29',
  'Mosiah 12:30',
  'Mosiah 12:31',
  'Mosiah 12:32',
  'Mosiah 12:33',
  'Mosiah 12:34',
  'Mosiah 12:35',
  'Mosiah 12:36',
  'Mosiah 12:37',
  'Mosiah 13:1',
  'Mosiah 13:2',
  'Mosiah 13:3',
  'Mosiah 13:4',
  'Mosiah 13:5',
  'Mosiah 13:6',
  'Mosiah 13:7',
  'Mosiah 13:8',
  'Mosiah 13:9',
  'Mosiah 13:10',
  'Mosiah 13:11',
  'Mosiah 13:12',
  'Mosiah 13:13',
  'Mosiah 13:14',
  'Mosiah 13:15',
  'Mosiah 13:16',
  'Mosiah 13:17',
  'Mosiah 13:18',
  'Mosiah 13:19',
  'Mosiah 13:20',
  'Mosiah 13:21',
  'Mosiah 13:22',
  'Mosiah 13:23',
  'Mosiah 13:24',
  'Mosiah 13:25',
  'Mosiah 13:26',
  'Mosiah 13:27',
  'Mosiah 13:28',
  'Mosiah 13:29',
  'Mosiah 13:30',
  'Mosiah 13:31',
  'Mosiah 13:32',
  'Mosiah 13:33',
  'Mosiah 13:34',
  'Mosiah 13:35',
  'Mosiah 14:1',
  'Mosiah 14:2',
  'Mosiah 14:3',
  'Mosiah 14:4',
  'Mosiah 14:5',
  'Mosiah 14:6',
  'Mosiah 14:7',
  'Mosiah 14:8',
  'Mosiah 14:9',
  'Mosiah 14:10',
  'Mosiah 14:11',
  'Mosiah 14:12',
  'Mosiah 15:1',
  'Mosiah 15:2',
  'Mosiah 15:3',
  'Mosiah 15:4',
  'Mosiah 15:5',
  'Mosiah 15:6',
  'Mosiah 15:7',
  'Mosiah 15:8',
  'Mosiah 15:9',
  'Mosiah 15:10',
  'Mosiah 15:11',
  'Mosiah 15:12',
  'Mosiah 15:13',
  'Mosiah 15:14',
  'Mosiah 15:15',
  'Mosiah 15:16',
  'Mosiah 15:17',
  'Mosiah 15:18',
  'Mosiah 15:19',
  'Mosiah 15:20',
  'Mosiah 15:21',
  'Mosiah 15:22',
  'Mosiah 15:23',
  'Mosiah 15:24',
  'Mosiah 15:25',
  'Mosiah 15:26',
  'Mosiah 15:27',
  'Mosiah 15:28',
  'Mosiah 15:29',
  'Mosiah 15:30',
  'Mosiah 15:31',
  'Mosiah 16:1',
  'Mosiah 16:2',
  'Mosiah 16:3',
  'Mosiah 16:4',
  'Mosiah 16:5',
  'Mosiah 16:6',
  'Mosiah 16:7',
  'Mosiah 16:8',
  'Mosiah 16:9',
  'Mosiah 16:10',
  'Mosiah 16:11',
  'Mosiah 16:12',
  'Mosiah 16:13',
  'Mosiah 16:14',
  'Mosiah 16:15',
  'Mosiah 17:1',
  'Mosiah 17:2',
  'Mosiah 17:3',
  'Mosiah 17:4',
  'Mosiah 17:5',
  'Mosiah 17:6',
  'Mosiah 17:7',
  'Mosiah 17:8',
  'Mosiah 17:9',
  'Mosiah 17:10',
  'Mosiah 17:11',
  'Mosiah 17:12',
  'Mosiah 17:13',
  'Mosiah 17:14',
  'Mosiah 17:15',
  'Mosiah 17:16',
  'Mosiah 17:17',
  'Mosiah 17:18',
  'Mosiah 17:19',
  'Mosiah 17:20',
  'Mosiah 18:1',
  'Mosiah 18:2',
  'Mosiah 18:3',
  'Mosiah 18:4',
  'Mosiah 18:5',
  'Mosiah 18:6',
  'Mosiah 18:7',
  'Mosiah 18:8',
  'Mosiah 18:9',
  'Mosiah 18:10',
  'Mosiah 18:11',
  'Mosiah 18:12',
  'Mosiah 18:13',
  'Mosiah 18:14',
  'Mosiah 18:15',
  'Mosiah 18:16',
  'Mosiah 18:17',
  'Mosiah 18:18',
  'Mosiah 18:19',
  'Mosiah 18:20',
  'Mosiah 18:21',
  'Mosiah 18:22',
  'Mosiah 18:23',
  'Mosiah 18:24',
  'Mosiah 18:25',
  'Mosiah 18:26',
  'Mosiah 18:27',
  'Mosiah 18:28',
  'Mosiah 18:29',
  'Mosiah 18:30',
  'Mosiah 18:31',
  'Mosiah 18:32',
  'Mosiah 18:33',
  'Mosiah 18:34',
  'Mosiah 18:35',
  'Mosiah 19:1',
  'Mosiah 19:2',
  'Mosiah 19:3',
  'Mosiah 19:4',
  'Mosiah 19:5',
  'Mosiah 19:6',
  'Mosiah 19:7',
  'Mosiah 19:8',
  'Mosiah 19:9',
  'Mosiah 19:10',
  'Mosiah 19:11',
  'Mosiah 19:12',
  'Mosiah 19:13',
  'Mosiah 19:14',
  'Mosiah 19:15',
  'Mosiah 19:16',
  'Mosiah 19:17',
  'Mosiah 19:18',
  'Mosiah 19:19',
  'Mosiah 19:20',
  'Mosiah 19:21',
  'Mosiah 19:22',
  'Mosiah 19:23',
  'Mosiah 19:24',
  'Mosiah 19:25',
  'Mosiah 19:26',
  'Mosiah 19:27',
  'Mosiah 19:28',
  'Mosiah 19:29',
  'Mosiah 20:1',
  'Mosiah 20:2',
  'Mosiah 20:3',
  'Mosiah 20:4',
  'Mosiah 20:5',
  'Mosiah 20:6',
  'Mosiah 20:7',
  'Mosiah 20:8',
  'Mosiah 20:9',
  'Mosiah 20:10',
  'Mosiah 20:11',
  'Mosiah 20:12',
  'Mosiah 20:13',
  'Mosiah 20:14',
  'Mosiah 20:15',
  'Mosiah 20:16',
  'Mosiah 20:17',
  'Mosiah 20:18',
  'Mosiah 20:19',
  'Mosiah 20:20',
  'Mosiah 20:21',
  'Mosiah 20:22',
  'Mosiah 20:23',
  'Mosiah 20:24',
  'Mosiah 20:25',
  'Mosiah 20:26',
  'Mosiah 21:1',
  'Mosiah 21:2',
  'Mosiah 21:3',
  'Mosiah 21:4',
  'Mosiah 21:5',
  'Mosiah 21:6',
  'Mosiah 21:7',
  'Mosiah 21:8',
  'Mosiah 21:9',
  'Mosiah 21:10',
  'Mosiah 21:11',
  'Mosiah 21:12',
  'Mosiah 21:13',
  'Mosiah 21:14',
  'Mosiah 21:15',
  'Mosiah 21:16',
  'Mosiah 21:17',
  'Mosiah 21:18',
  'Mosiah 21:19',
  'Mosiah 21:20',
  'Mosiah 21:21',
  'Mosiah 21:22',
  'Mosiah 21:23',
  'Mosiah 21:24',
  'Mosiah 21:25',
  'Mosiah 21:26',
  'Mosiah 21:27',
  'Mosiah 21:28',
  'Mosiah 21:29',
  'Mosiah 21:30',
  'Mosiah 21:31',
  'Mosiah 21:32',
  'Mosiah 21:33',
  'Mosiah 21:34',
  'Mosiah 21:35',
  'Mosiah 21:36',
  'Mosiah 22:1',
  'Mosiah 22:2',
  'Mosiah 22:3',
  'Mosiah 22:4',
  'Mosiah 22:5',
  'Mosiah 22:6',
  'Mosiah 22:7',
  'Mosiah 22:8',
  'Mosiah 22:9',
  'Mosiah 22:10',
  'Mosiah 22:11',
  'Mosiah 22:12',
  'Mosiah 22:13',
  'Mosiah 22:14',
  'Mosiah 22:15',
  'Mosiah 22:16',
  'Mosiah 23:1',
  'Mosiah 23:2',
  'Mosiah 23:3',
  'Mosiah 23:4',
  'Mosiah 23:5',
  'Mosiah 23:6',
  'Mosiah 23:7',
  'Mosiah 23:8',
  'Mosiah 23:9',
  'Mosiah 23:10',
  'Mosiah 23:11',
  'Mosiah 23:12',
  'Mosiah 23:13',
  'Mosiah 23:14',
  'Mosiah 23:15',
  'Mosiah 23:16',
  'Mosiah 23:17',
  'Mosiah 23:18',
  'Mosiah 23:19',
  'Mosiah 23:20',
  'Mosiah 23:21',
  'Mosiah 23:22',
  'Mosiah 23:23',
  'Mosiah 23:24',
  'Mosiah 23:25',
  'Mosiah 23:26',
  'Mosiah 23:27',
  'Mosiah 23:28',
  'Mosiah 23:29',
  'Mosiah 23:30',
  'Mosiah 23:31',
  'Mosiah 23:32',
  'Mosiah 23:33',
  'Mosiah 23:34',
  'Mosiah 23:35',
  'Mosiah 23:36',
  'Mosiah 23:37',
  'Mosiah 23:38',
  'Mosiah 23:39',
  'Mosiah 24:1',
  'Mosiah 24:2',
  'Mosiah 24:3',
  'Mosiah 24:4',
  'Mosiah 24:5',
  'Mosiah 24:6',
  'Mosiah 24:7',
  'Mosiah 24:8',
  'Mosiah 24:9',
  'Mosiah 24:10',
  'Mosiah 24:11',
  'Mosiah 24:12',
  'Mosiah 24:13',
  'Mosiah 24:14',
  'Mosiah 24:15',
  'Mosiah 24:16',
  'Mosiah 24:17',
  'Mosiah 24:18',
  'Mosiah 24:19',
  'Mosiah 24:20',
  'Mosiah 24:21',
  'Mosiah 24:22',
  'Mosiah 24:23',
  'Mosiah 24:24',
  'Mosiah 24:25',
  'Mosiah 25:1',
  'Mosiah 25:2',
  'Mosiah 25:3',
  'Mosiah 25:4',
  'Mosiah 25:5',
  'Mosiah 25:6',
  'Mosiah 25:7',
  'Mosiah 25:8',
  'Mosiah 25:9',
  'Mosiah 25:10',
  'Mosiah 25:11',
  'Mosiah 25:12',
  'Mosiah 25:13',
  'Mosiah 25:14',
  'Mosiah 25:15',
  'Mosiah 25:16',
  'Mosiah 25:17',
  'Mosiah 25:18',
  'Mosiah 25:19',
  'Mosiah 25:20',
  'Mosiah 25:21',
  'Mosiah 25:22',
  'Mosiah 25:23',
  'Mosiah 25:24',
  'Mosiah 26:1',
  'Mosiah 26:2',
  'Mosiah 26:3',
  'Mosiah 26:4',
  'Mosiah 26:5',
  'Mosiah 26:6',
  'Mosiah 26:7',
  'Mosiah 26:8',
  'Mosiah 26:9',
  'Mosiah 26:10',
  'Mosiah 26:11',
  'Mosiah 26:12',
  'Mosiah 26:13',
  'Mosiah 26:14',
  'Mosiah 26:15',
  'Mosiah 26:16',
  'Mosiah 26:17',
  'Mosiah 26:18',
  'Mosiah 26:19',
  'Mosiah 26:20',
  'Mosiah 26:21',
  'Mosiah 26:22',
  'Mosiah 26:23',
  'Mosiah 26:24',
  'Mosiah 26:25',
  'Mosiah 26:26',
  'Mosiah 26:27',
  'Mosiah 26:28',
  'Mosiah 26:29',
  'Mosiah 26:30',
  'Mosiah 26:31',
  'Mosiah 26:32',
  'Mosiah 26:33',
  'Mosiah 26:34',
  'Mosiah 26:35',
  'Mosiah 26:36',
  'Mosiah 26:37',
  'Mosiah 26:38',
  'Mosiah 26:39',
  'Mosiah 27:1',
  'Mosiah 27:2',
  'Mosiah 27:3',
  'Mosiah 27:4',
  'Mosiah 27:5',
  'Mosiah 27:6',
  'Mosiah 27:7',
  'Mosiah 27:8',
  'Mosiah 27:9',
  'Mosiah 27:10',
  'Mosiah 27:11',
  'Mosiah 27:12',
  'Mosiah 27:13',
  'Mosiah 27:14',
  'Mosiah 27:15',
  'Mosiah 27:16',
  'Mosiah 27:17',
  'Mosiah 27:18',
  'Mosiah 27:19',
  'Mosiah 27:20',
  'Mosiah 27:21',
  'Mosiah 27:22',
  'Mosiah 27:23',
  'Mosiah 27:24',
  'Mosiah 27:25',
  'Mosiah 27:26',
  'Mosiah 27:27',
  'Mosiah 27:28',
  'Mosiah 27:29',
  'Mosiah 27:30',
  'Mosiah 27:31',
  'Mosiah 27:32',
  'Mosiah 27:33',
  'Mosiah 27:34',
  'Mosiah 27:35',
  'Mosiah 27:36',
  'Mosiah 27:37',
  'Mosiah 28:1',
  'Mosiah 28:2',
  'Mosiah 28:3',
  'Mosiah 28:4',
  'Mosiah 28:5',
  'Mosiah 28:6',
  'Mosiah 28:7',
  'Mosiah 28:8',
  'Mosiah 28:9',
  'Mosiah 28:10',
  'Mosiah 28:11',
  'Mosiah 28:12',
  'Mosiah 28:13',
  'Mosiah 28:14',
  'Mosiah 28:15',
  'Mosiah 28:16',
  'Mosiah 28:17',
  'Mosiah 28:18',
  'Mosiah 28:19',
  'Mosiah 28:20',
  'Mosiah 29:1',
  'Mosiah 29:2',
  'Mosiah 29:3',
  'Mosiah 29:4',
  'Mosiah 29:5',
  'Mosiah 29:6',
  'Mosiah 29:7',
  'Mosiah 29:8',
  'Mosiah 29:9',
  'Mosiah 29:10',
  'Mosiah 29:11',
  'Mosiah 29:12',
  'Mosiah 29:13',
  'Mosiah 29:14',
  'Mosiah 29:15',
  'Mosiah 29:16',
  'Mosiah 29:17',
  'Mosiah 29:18',
  'Mosiah 29:19',
  'Mosiah 29:20',
  'Mosiah 29:21',
  'Mosiah 29:22',
  'Mosiah 29:23',
  'Mosiah 29:24',
  'Mosiah 29:25',
  'Mosiah 29:26',
  'Mosiah 29:27',
  'Mosiah 29:28',
  'Mosiah 29:29',
  'Mosiah 29:30',
  'Mosiah 29:31',
  'Mosiah 29:32',
  'Mosiah 29:33',
  'Mosiah 29:34',
  'Mosiah 29:35',
  'Mosiah 29:36',
  'Mosiah 29:37',
  'Mosiah 29:38',
  'Mosiah 29:39',
  'Mosiah 29:40',
  'Mosiah 29:41',
  'Mosiah 29:42',
  'Mosiah 29:43',
  'Mosiah 29:44',
  'Mosiah 29:45',
  'Mosiah 29:46',
  'Mosiah 29:47',
  'Alma 1:1',
  'Alma 1:2',
  'Alma 1:3',
  'Alma 1:4',
  'Alma 1:5',
  'Alma 1:6',
  'Alma 1:7',
  'Alma 1:8',
  'Alma 1:9',
  'Alma 1:10',
  'Alma 1:11',
  'Alma 1:12',
  'Alma 1:13',
  'Alma 1:14',
  'Alma 1:15',
  'Alma 1:16',
  'Alma 1:17',
  'Alma 1:18',
  'Alma 1:19',
  'Alma 1:20',
  'Alma 1:21',
  'Alma 1:22',
  'Alma 1:23',
  'Alma 1:24',
  'Alma 1:25',
  'Alma 1:26',
  'Alma 1:27',
  'Alma 1:28',
  'Alma 1:29',
  'Alma 1:30',
  'Alma 1:31',
  'Alma 1:32',
  'Alma 1:33',
  'Alma 2:1',
  'Alma 2:2',
  'Alma 2:3',
  'Alma 2:4',
  'Alma 2:5',
  'Alma 2:6',
  'Alma 2:7',
  'Alma 2:8',
  'Alma 2:9',
  'Alma 2:10',
  'Alma 2:11',
  'Alma 2:12',
  'Alma 2:13',
  'Alma 2:14',
  'Alma 2:15',
  'Alma 2:16',
  'Alma 2:17',
  'Alma 2:18',
  'Alma 2:19',
  'Alma 2:20',
  'Alma 2:21',
  'Alma 2:22',
  'Alma 2:23',
  'Alma 2:24',
  'Alma 2:25',
  'Alma 2:26',
  'Alma 2:27',
  'Alma 2:28',
  'Alma 2:29',
  'Alma 2:30',
  'Alma 2:31',
  'Alma 2:32',
  'Alma 2:33',
  'Alma 2:34',
  'Alma 2:35',
  'Alma 2:36',
  'Alma 2:37',
  'Alma 2:38',
  'Alma 3:1',
  'Alma 3:2',
  'Alma 3:3',
  'Alma 3:4',
  'Alma 3:5',
  'Alma 3:6',
  'Alma 3:7',
  'Alma 3:8',
  'Alma 3:9',
  'Alma 3:10',
  'Alma 3:11',
  'Alma 3:12',
  'Alma 3:13',
  'Alma 3:14',
  'Alma 3:15',
  'Alma 3:16',
  'Alma 3:17',
  'Alma 3:18',
  'Alma 3:19',
  'Alma 3:20',
  'Alma 3:21',
  'Alma 3:22',
  'Alma 3:23',
  'Alma 3:24',
  'Alma 3:25',
  'Alma 3:26',
  'Alma 3:27',
  'Alma 4:1',
  'Alma 4:2',
  'Alma 4:3',
  'Alma 4:4',
  'Alma 4:5',
  'Alma 4:6',
  'Alma 4:7',
  'Alma 4:8',
  'Alma 4:9',
  'Alma 4:10',
  'Alma 4:11',
  'Alma 4:12',
  'Alma 4:13',
  'Alma 4:14',
  'Alma 4:15',
  'Alma 4:16',
  'Alma 4:17',
  'Alma 4:18',
  'Alma 4:19',
  'Alma 4:20',
  'Alma 5:1',
  'Alma 5:2',
  'Alma 5:3',
  'Alma 5:4',
  'Alma 5:5',
  'Alma 5:6',
  'Alma 5:7',
  'Alma 5:8',
  'Alma 5:9',
  'Alma 5:10',
  'Alma 5:11',
  'Alma 5:12',
  'Alma 5:13',
  'Alma 5:14',
  'Alma 5:15',
  'Alma 5:16',
  'Alma 5:17',
  'Alma 5:18',
  'Alma 5:19',
  'Alma 5:20',
  'Alma 5:21',
  'Alma 5:22',
  'Alma 5:23',
  'Alma 5:24',
  'Alma 5:25',
  'Alma 5:26',
  'Alma 5:27',
  'Alma 5:28',
  'Alma 5:29',
  'Alma 5:30',
  'Alma 5:31',
  'Alma 5:32',
  'Alma 5:33',
  'Alma 5:34',
  'Alma 5:35',
  'Alma 5:36',
  'Alma 5:37',
  'Alma 5:38',
  'Alma 5:39',
  'Alma 5:40',
  'Alma 5:41',
  'Alma 5:42',
  'Alma 5:43',
  'Alma 5:44',
  'Alma 5:45',
  'Alma 5:46',
  'Alma 5:47',
  'Alma 5:48',
  'Alma 5:49',
  'Alma 5:50',
  'Alma 5:51',
  'Alma 5:52',
  'Alma 5:53',
  'Alma 5:54',
  'Alma 5:55',
  'Alma 5:56',
  'Alma 5:57',
  'Alma 5:58',
  'Alma 5:59',
  'Alma 5:60',
  'Alma 5:61',
  'Alma 5:62',
  'Alma 6:1',
  'Alma 6:2',
  'Alma 6:3',
  'Alma 6:4',
  'Alma 6:5',
  'Alma 6:6',
  'Alma 6:7',
  'Alma 6:8',
  'Alma 7:1',
  'Alma 7:2',
  'Alma 7:3',
  'Alma 7:4',
  'Alma 7:5',
  'Alma 7:6',
  'Alma 7:7',
  'Alma 7:8',
  'Alma 7:9',
  'Alma 7:10',
  'Alma 7:11',
  'Alma 7:12',
  'Alma 7:13',
  'Alma 7:14',
  'Alma 7:15',
  'Alma 7:16',
  'Alma 7:17',
  'Alma 7:18',
  'Alma 7:19',
  'Alma 7:20',
  'Alma 7:21',
  'Alma 7:22',
  'Alma 7:23',
  'Alma 7:24',
  'Alma 7:25',
  'Alma 7:26',
  'Alma 7:27',
  'Alma 8:1',
  'Alma 8:2',
  'Alma 8:3',
  'Alma 8:4',
  'Alma 8:5',
  'Alma 8:6',
  'Alma 8:7',
  'Alma 8:8',
  'Alma 8:9',
  'Alma 8:10',
  'Alma 8:11',
  'Alma 8:12',
  'Alma 8:13',
  'Alma 8:14',
  'Alma 8:15',
  'Alma 8:16',
  'Alma 8:17',
  'Alma 8:18',
  'Alma 8:19',
  'Alma 8:20',
  'Alma 8:21',
  'Alma 8:22',
  'Alma 8:23',
  'Alma 8:24',
  'Alma 8:25',
  'Alma 8:26',
  'Alma 8:27',
  'Alma 8:28',
  'Alma 8:29',
  'Alma 8:30',
  'Alma 8:31',
  'Alma 8:32',
  'Alma 9:1',
  'Alma 9:2',
  'Alma 9:3',
  'Alma 9:4',
  'Alma 9:5',
  'Alma 9:6',
  'Alma 9:7',
  'Alma 9:8',
  'Alma 9:9',
  'Alma 9:10',
  'Alma 9:11',
  'Alma 9:12',
  'Alma 9:13',
  'Alma 9:14',
  'Alma 9:15',
  'Alma 9:16',
  'Alma 9:17',
  'Alma 9:18',
  'Alma 9:19',
  'Alma 9:20',
  'Alma 9:21',
  'Alma 9:22',
  'Alma 9:23',
  'Alma 9:24',
  'Alma 9:25',
  'Alma 9:26',
  'Alma 9:27',
  'Alma 9:28',
  'Alma 9:29',
  'Alma 9:30',
  'Alma 9:31',
  'Alma 9:32',
  'Alma 9:33',
  'Alma 9:34',
  'Alma 10:1',
  'Alma 10:2',
  'Alma 10:3',
  'Alma 10:4',
  'Alma 10:5',
  'Alma 10:6',
  'Alma 10:7',
  'Alma 10:8',
  'Alma 10:9',
  'Alma 10:10',
  'Alma 10:11',
  'Alma 10:12',
  'Alma 10:13',
  'Alma 10:14',
  'Alma 10:15',
  'Alma 10:16',
  'Alma 10:17',
  'Alma 10:18',
  'Alma 10:19',
  'Alma 10:20',
  'Alma 10:21',
  'Alma 10:22',
  'Alma 10:23',
  'Alma 10:24',
  'Alma 10:25',
  'Alma 10:26',
  'Alma 10:27',
  'Alma 10:28',
  'Alma 10:29',
  'Alma 10:30',
  'Alma 10:31',
  'Alma 10:32',
  'Alma 11:1',
  'Alma 11:2',
  'Alma 11:3',
  'Alma 11:4',
  'Alma 11:5',
  'Alma 11:6',
  'Alma 11:7',
  'Alma 11:8',
  'Alma 11:9',
  'Alma 11:10',
  'Alma 11:11',
  'Alma 11:12',
  'Alma 11:13',
  'Alma 11:14',
  'Alma 11:15',
  'Alma 11:16',
  'Alma 11:17',
  'Alma 11:18',
  'Alma 11:19',
  'Alma 11:20',
  'Alma 11:21',
  'Alma 11:22',
  'Alma 11:23',
  'Alma 11:24',
  'Alma 11:25',
  'Alma 11:26',
  'Alma 11:27',
  'Alma 11:28',
  'Alma 11:29',
  'Alma 11:30',
  'Alma 11:31',
  'Alma 11:32',
  'Alma 11:33',
  'Alma 11:34',
  'Alma 11:35',
  'Alma 11:36',
  'Alma 11:37',
  'Alma 11:38',
  'Alma 11:39',
  'Alma 11:40',
  'Alma 11:41',
  'Alma 11:42',
  'Alma 11:43',
  'Alma 11:44',
  'Alma 11:45',
  'Alma 11:46',
  'Alma 12:1',
  'Alma 12:2',
  'Alma 12:3',
  'Alma 12:4',
  'Alma 12:5',
  'Alma 12:6',
  'Alma 12:7',
  'Alma 12:8',
  'Alma 12:9',
  'Alma 12:10',
  'Alma 12:11',
  'Alma 12:12',
  'Alma 12:13',
  'Alma 12:14',
  'Alma 12:15',
  'Alma 12:16',
  'Alma 12:17',
  'Alma 12:18',
  'Alma 12:19',
  'Alma 12:20',
  'Alma 12:21',
  'Alma 12:22',
  'Alma 12:23',
  'Alma 12:24',
  'Alma 12:25',
  'Alma 12:26',
  'Alma 12:27',
  'Alma 12:28',
  'Alma 12:29',
  'Alma 12:30',
  'Alma 12:31',
  'Alma 12:32',
  'Alma 12:33',
  'Alma 12:34',
  'Alma 12:35',
  'Alma 12:36',
  'Alma 12:37',
  'Alma 13:1',
  'Alma 13:2',
  'Alma 13:3',
  'Alma 13:4',
  'Alma 13:5',
  'Alma 13:6',
  'Alma 13:7',
  'Alma 13:8',
  'Alma 13:9',
  'Alma 13:10',
  'Alma 13:11',
  'Alma 13:12',
  'Alma 13:13',
  'Alma 13:14',
  'Alma 13:15',
  'Alma 13:16',
  'Alma 13:17',
  'Alma 13:18',
  'Alma 13:19',
  'Alma 13:20',
  'Alma 13:21',
  'Alma 13:22',
  'Alma 13:23',
  'Alma 13:24',
  'Alma 13:25',
  'Alma 13:26',
  'Alma 13:27',
  'Alma 13:28',
  'Alma 13:29',
  'Alma 13:30',
  'Alma 13:31',
  'Alma 14:1',
  'Alma 14:2',
  'Alma 14:3',
  'Alma 14:4',
  'Alma 14:5',
  'Alma 14:6',
  'Alma 14:7',
  'Alma 14:8',
  'Alma 14:9',
  'Alma 14:10',
  'Alma 14:11',
  'Alma 14:12',
  'Alma 14:13',
  'Alma 14:14',
  'Alma 14:15',
  'Alma 14:16',
  'Alma 14:17',
  'Alma 14:18',
  'Alma 14:19',
  'Alma 14:20',
  'Alma 14:21',
  'Alma 14:22',
  'Alma 14:23',
  'Alma 14:24',
  'Alma 14:25',
  'Alma 14:26',
  'Alma 14:27',
  'Alma 14:28',
  'Alma 14:29',
  'Alma 15:1',
  'Alma 15:2',
  'Alma 15:3',
  'Alma 15:4',
  'Alma 15:5',
  'Alma 15:6',
  'Alma 15:7',
  'Alma 15:8',
  'Alma 15:9',
  'Alma 15:10',
  'Alma 15:11',
  'Alma 15:12',
  'Alma 15:13',
  'Alma 15:14',
  'Alma 15:15',
  'Alma 15:16',
  'Alma 15:17',
  'Alma 15:18',
  'Alma 15:19',
  'Alma 16:1',
  'Alma 16:2',
  'Alma 16:3',
  'Alma 16:4',
  'Alma 16:5',
  'Alma 16:6',
  'Alma 16:7',
  'Alma 16:8',
  'Alma 16:9',
  'Alma 16:10',
  'Alma 16:11',
  'Alma 16:12',
  'Alma 16:13',
  'Alma 16:14',
  'Alma 16:15',
  'Alma 16:16',
  'Alma 16:17',
  'Alma 16:18',
  'Alma 16:19',
  'Alma 16:20',
  'Alma 16:21',
  'Alma 17:1',
  'Alma 17:2',
  'Alma 17:3',
  'Alma 17:4',
  'Alma 17:5',
  'Alma 17:6',
  'Alma 17:7',
  'Alma 17:8',
  'Alma 17:9',
  'Alma 17:10',
  'Alma 17:11',
  'Alma 17:12',
  'Alma 17:13',
  'Alma 17:14',
  'Alma 17:15',
  'Alma 17:16',
  'Alma 17:17',
  'Alma 17:18',
  'Alma 17:19',
  'Alma 17:20',
  'Alma 17:21',
  'Alma 17:22',
  'Alma 17:23',
  'Alma 17:24',
  'Alma 17:25',
  'Alma 17:26',
  'Alma 17:27',
  'Alma 17:28',
  'Alma 17:29',
  'Alma 17:30',
  'Alma 17:31',
  'Alma 17:32',
  'Alma 17:33',
  'Alma 17:34',
  'Alma 17:35',
  'Alma 17:36',
  'Alma 17:37',
  'Alma 17:38',
  'Alma 17:39',
  'Alma 18:1',
  'Alma 18:2',
  'Alma 18:3',
  'Alma 18:4',
  'Alma 18:5',
  'Alma 18:6',
  'Alma 18:7',
  'Alma 18:8',
  'Alma 18:9',
  'Alma 18:10',
  'Alma 18:11',
  'Alma 18:12',
  'Alma 18:13',
  'Alma 18:14',
  'Alma 18:15',
  'Alma 18:16',
  'Alma 18:17',
  'Alma 18:18',
  'Alma 18:19',
  'Alma 18:20',
  'Alma 18:21',
  'Alma 18:22',
  'Alma 18:23',
  'Alma 18:24',
  'Alma 18:25',
  'Alma 18:26',
  'Alma 18:27',
  'Alma 18:28',
  'Alma 18:29',
  'Alma 18:30',
  'Alma 18:31',
  'Alma 18:32',
  'Alma 18:33',
  'Alma 18:34',
  'Alma 18:35',
  'Alma 18:36',
  'Alma 18:37',
  'Alma 18:38',
  'Alma 18:39',
  'Alma 18:40',
  'Alma 18:41',
  'Alma 18:42',
  'Alma 18:43',
  'Alma 19:1',
  'Alma 19:2',
  'Alma 19:3',
  'Alma 19:4',
  'Alma 19:5',
  'Alma 19:6',
  'Alma 19:7',
  'Alma 19:8',
  'Alma 19:9',
  'Alma 19:10',
  'Alma 19:11',
  'Alma 19:12',
  'Alma 19:13',
  'Alma 19:14',
  'Alma 19:15',
  'Alma 19:16',
  'Alma 19:17',
  'Alma 19:18',
  'Alma 19:19',
  'Alma 19:20',
  'Alma 19:21',
  'Alma 19:22',
  'Alma 19:23',
  'Alma 19:24',
  'Alma 19:25',
  'Alma 19:26',
  'Alma 19:27',
  'Alma 19:28',
  'Alma 19:29',
  'Alma 19:30',
  'Alma 19:31',
  'Alma 19:32',
  'Alma 19:33',
  'Alma 19:34',
  'Alma 19:35',
  'Alma 19:36',
  'Alma 20:1',
  'Alma 20:2',
  'Alma 20:3',
  'Alma 20:4',
  'Alma 20:5',
  'Alma 20:6',
  'Alma 20:7',
  'Alma 20:8',
  'Alma 20:9',
  'Alma 20:10',
  'Alma 20:11',
  'Alma 20:12',
  'Alma 20:13',
  'Alma 20:14',
  'Alma 20:15',
  'Alma 20:16',
  'Alma 20:17',
  'Alma 20:18',
  'Alma 20:19',
  'Alma 20:20',
  'Alma 20:21',
  'Alma 20:22',
  'Alma 20:23',
  'Alma 20:24',
  'Alma 20:25',
  'Alma 20:26',
  'Alma 20:27',
  'Alma 20:28',
  'Alma 20:29',
  'Alma 20:30',
  'Alma 21:1',
  'Alma 21:2',
  'Alma 21:3',
  'Alma 21:4',
  'Alma 21:5',
  'Alma 21:6',
  'Alma 21:7',
  'Alma 21:8',
  'Alma 21:9',
  'Alma 21:10',
  'Alma 21:11',
  'Alma 21:12',
  'Alma 21:13',
  'Alma 21:14',
  'Alma 21:15',
  'Alma 21:16',
  'Alma 21:17',
  'Alma 21:18',
  'Alma 21:19',
  'Alma 21:20',
  'Alma 21:21',
  'Alma 21:22',
  'Alma 21:23',
  'Alma 22:1',
  'Alma 22:2',
  'Alma 22:3',
  'Alma 22:4',
  'Alma 22:5',
  'Alma 22:6',
  'Alma 22:7',
  'Alma 22:8',
  'Alma 22:9',
  'Alma 22:10',
  'Alma 22:11',
  'Alma 22:12',
  'Alma 22:13',
  'Alma 22:14',
  'Alma 22:15',
  'Alma 22:16',
  'Alma 22:17',
  'Alma 22:18',
  'Alma 22:19',
  'Alma 22:20',
  'Alma 22:21',
  'Alma 22:22',
  'Alma 22:23',
  'Alma 22:24',
  'Alma 22:25',
  'Alma 22:26',
  'Alma 22:27',
  'Alma 22:28',
  'Alma 22:29',
  'Alma 22:30',
  'Alma 22:31',
  'Alma 22:32',
  'Alma 22:33',
  'Alma 22:34',
  'Alma 22:35',
  'Alma 23:1',
  'Alma 23:2',
  'Alma 23:3',
  'Alma 23:4',
  'Alma 23:5',
  'Alma 23:6',
  'Alma 23:7',
  'Alma 23:8',
  'Alma 23:9',
  'Alma 23:10',
  'Alma 23:11',
  'Alma 23:12',
  'Alma 23:13',
  'Alma 23:14',
  'Alma 23:15',
  'Alma 23:16',
  'Alma 23:17',
  'Alma 23:18',
  'Alma 24:1',
  'Alma 24:2',
  'Alma 24:3',
  'Alma 24:4',
  'Alma 24:5',
  'Alma 24:6',
  'Alma 24:7',
  'Alma 24:8',
  'Alma 24:9',
  'Alma 24:10',
  'Alma 24:11',
  'Alma 24:12',
  'Alma 24:13',
  'Alma 24:14',
  'Alma 24:15',
  'Alma 24:16',
  'Alma 24:17',
  'Alma 24:18',
  'Alma 24:19',
  'Alma 24:20',
  'Alma 24:21',
  'Alma 24:22',
  'Alma 24:23',
  'Alma 24:24',
  'Alma 24:25',
  'Alma 24:26',
  'Alma 24:27',
  'Alma 24:28',
  'Alma 24:29',
  'Alma 24:30',
  'Alma 25:1',
  'Alma 25:2',
  'Alma 25:3',
  'Alma 25:4',
  'Alma 25:5',
  'Alma 25:6',
  'Alma 25:7',
  'Alma 25:8',
  'Alma 25:9',
  'Alma 25:10',
  'Alma 25:11',
  'Alma 25:12',
  'Alma 25:13',
  'Alma 25:14',
  'Alma 25:15',
  'Alma 25:16',
  'Alma 25:17',
  'Alma 26:1',
  'Alma 26:2',
  'Alma 26:3',
  'Alma 26:4',
  'Alma 26:5',
  'Alma 26:6',
  'Alma 26:7',
  'Alma 26:8',
  'Alma 26:9',
  'Alma 26:10',
  'Alma 26:11',
  'Alma 26:12',
  'Alma 26:13',
  'Alma 26:14',
  'Alma 26:15',
  'Alma 26:16',
  'Alma 26:17',
  'Alma 26:18',
  'Alma 26:19',
  'Alma 26:20',
  'Alma 26:21',
  'Alma 26:22',
  'Alma 26:23',
  'Alma 26:24',
  'Alma 26:25',
  'Alma 26:26',
  'Alma 26:27',
  'Alma 26:28',
  'Alma 26:29',
  'Alma 26:30',
  'Alma 26:31',
  'Alma 26:32',
  'Alma 26:33',
  'Alma 26:34',
  'Alma 26:35',
  'Alma 26:36',
  'Alma 26:37',
  'Alma 27:1',
  'Alma 27:2',
  'Alma 27:3',
  'Alma 27:4',
  'Alma 27:5',
  'Alma 27:6',
  'Alma 27:7',
  'Alma 27:8',
  'Alma 27:9',
  'Alma 27:10',
  'Alma 27:11',
  'Alma 27:12',
  'Alma 27:13',
  'Alma 27:14',
  'Alma 27:15',
  'Alma 27:16',
  'Alma 27:17',
  'Alma 27:18',
  'Alma 27:19',
  'Alma 27:20',
  'Alma 27:21',
  'Alma 27:22',
  'Alma 27:23',
  'Alma 27:24',
  'Alma 27:25',
  'Alma 27:26',
  'Alma 27:27',
  'Alma 27:28',
  'Alma 27:29',
  'Alma 27:30',
  'Alma 28:1',
  'Alma 28:2',
  'Alma 28:3',
  'Alma 28:4',
  'Alma 28:5',
  'Alma 28:6',
  'Alma 28:7',
  'Alma 28:8',
  'Alma 28:9',
  'Alma 28:10',
  'Alma 28:11',
  'Alma 28:12',
  'Alma 28:13',
  'Alma 28:14',
  'Alma 29:1',
  'Alma 29:2',
  'Alma 29:3',
  'Alma 29:4',
  'Alma 29:5',
  'Alma 29:6',
  'Alma 29:7',
  'Alma 29:8',
  'Alma 29:9',
  'Alma 29:10',
  'Alma 29:11',
  'Alma 29:12',
  'Alma 29:13',
  'Alma 29:14',
  'Alma 29:15',
  'Alma 29:16',
  'Alma 29:17',
  'Alma 30:1',
  'Alma 30:2',
  'Alma 30:3',
  'Alma 30:4',
  'Alma 30:5',
  'Alma 30:6',
  'Alma 30:7',
  'Alma 30:8',
  'Alma 30:9',
  'Alma 30:10',
  'Alma 30:11',
  'Alma 30:12',
  'Alma 30:13',
  'Alma 30:14',
  'Alma 30:15',
  'Alma 30:16',
  'Alma 30:17',
  'Alma 30:18',
  'Alma 30:19',
  'Alma 30:20',
  'Alma 30:21',
  'Alma 30:22',
  'Alma 30:23',
  'Alma 30:24',
  'Alma 30:25',
  'Alma 30:26',
  'Alma 30:27',
  'Alma 30:28',
  'Alma 30:29',
  'Alma 30:30',
  'Alma 30:31',
  'Alma 30:32',
  'Alma 30:33',
  'Alma 30:34',
  'Alma 30:35',
  'Alma 30:36',
  'Alma 30:37',
  'Alma 30:38',
  'Alma 30:39',
  'Alma 30:40',
  'Alma 30:41',
  'Alma 30:42',
  'Alma 30:43',
  'Alma 30:44',
  'Alma 30:45',
  'Alma 30:46',
  'Alma 30:47',
  'Alma 30:48',
  'Alma 30:49',
  'Alma 30:50',
  'Alma 30:51',
  'Alma 30:52',
  'Alma 30:53',
  'Alma 30:54',
  'Alma 30:55',
  'Alma 30:56',
  'Alma 30:57',
  'Alma 30:58',
  'Alma 30:59',
  'Alma 30:60',
  'Alma 31:1',
  'Alma 31:2',
  'Alma 31:3',
  'Alma 31:4',
  'Alma 31:5',
  'Alma 31:6',
  'Alma 31:7',
  'Alma 31:8',
  'Alma 31:9',
  'Alma 31:10',
  'Alma 31:11',
  'Alma 31:12',
  'Alma 31:13',
  'Alma 31:14',
  'Alma 31:15',
  'Alma 31:16',
  'Alma 31:17',
  'Alma 31:18',
  'Alma 31:19',
  'Alma 31:20',
  'Alma 31:21',
  'Alma 31:22',
  'Alma 31:23',
  'Alma 31:24',
  'Alma 31:25',
  'Alma 31:26',
  'Alma 31:27',
  'Alma 31:28',
  'Alma 31:29',
  'Alma 31:30',
  'Alma 31:31',
  'Alma 31:32',
  'Alma 31:33',
  'Alma 31:34',
  'Alma 31:35',
  'Alma 31:36',
  'Alma 31:37',
  'Alma 31:38',
  'Alma 32:1',
  'Alma 32:2',
  'Alma 32:3',
  'Alma 32:4',
  'Alma 32:5',
  'Alma 32:6',
  'Alma 32:7',
  'Alma 32:8',
  'Alma 32:9',
  'Alma 32:10',
  'Alma 32:11',
  'Alma 32:12',
  'Alma 32:13',
  'Alma 32:14',
  'Alma 32:15',
  'Alma 32:16',
  'Alma 32:17',
  'Alma 32:18',
  'Alma 32:19',
  'Alma 32:20',
  'Alma 32:21',
  'Alma 32:22',
  'Alma 32:23',
  'Alma 32:24',
  'Alma 32:25',
  'Alma 32:26',
  'Alma 32:27',
  'Alma 32:28',
  'Alma 32:29',
  'Alma 32:30',
  'Alma 32:31',
  'Alma 32:32',
  'Alma 32:33',
  'Alma 32:34',
  'Alma 32:35',
  'Alma 32:36',
  'Alma 32:37',
  'Alma 32:38',
  'Alma 32:39',
  'Alma 32:40',
  'Alma 32:41',
  'Alma 32:42',
  'Alma 32:43',
  'Alma 33:1',
  'Alma 33:2',
  'Alma 33:3',
  'Alma 33:4',
  'Alma 33:5',
  'Alma 33:6',
  'Alma 33:7',
  'Alma 33:8',
  'Alma 33:9',
  'Alma 33:10',
  'Alma 33:11',
  'Alma 33:12',
  'Alma 33:13',
  'Alma 33:14',
  'Alma 33:15',
  'Alma 33:16',
  'Alma 33:17',
  'Alma 33:18',
  'Alma 33:19',
  'Alma 33:20',
  'Alma 33:21',
  'Alma 33:22',
  'Alma 33:23',
  'Alma 34:1',
  'Alma 34:2',
  'Alma 34:3',
  'Alma 34:4',
  'Alma 34:5',
  'Alma 34:6',
  'Alma 34:7',
  'Alma 34:8',
  'Alma 34:9',
  'Alma 34:10',
  'Alma 34:11',
  'Alma 34:12',
  'Alma 34:13',
  'Alma 34:14',
  'Alma 34:15',
  'Alma 34:16',
  'Alma 34:17',
  'Alma 34:18',
  'Alma 34:19',
  'Alma 34:20',
  'Alma 34:21',
  'Alma 34:22',
  'Alma 34:23',
  'Alma 34:24',
  'Alma 34:25',
  'Alma 34:26',
  'Alma 34:27',
  'Alma 34:28',
  'Alma 34:29',
  'Alma 34:30',
  'Alma 34:31',
  'Alma 34:32',
  'Alma 34:33',
  'Alma 34:34',
  'Alma 34:35',
  'Alma 34:36',
  'Alma 34:37',
  'Alma 34:38',
  'Alma 34:39',
  'Alma 34:40',
  'Alma 34:41',
  'Alma 35:1',
  'Alma 35:2',
  'Alma 35:3',
  'Alma 35:4',
  'Alma 35:5',
  'Alma 35:6',
  'Alma 35:7',
  'Alma 35:8',
  'Alma 35:9',
  'Alma 35:10',
  'Alma 35:11',
  'Alma 35:12',
  'Alma 35:13',
  'Alma 35:14',
  'Alma 35:15',
  'Alma 35:16',
  'Alma 36:1',
  'Alma 36:2',
  'Alma 36:3',
  'Alma 36:4',
  'Alma 36:5',
  'Alma 36:6',
  'Alma 36:7',
  'Alma 36:8',
  'Alma 36:9',
  'Alma 36:10',
  'Alma 36:11',
  'Alma 36:12',
  'Alma 36:13',
  'Alma 36:14',
  'Alma 36:15',
  'Alma 36:16',
  'Alma 36:17',
  'Alma 36:18',
  'Alma 36:19',
  'Alma 36:20',
  'Alma 36:21',
  'Alma 36:22',
  'Alma 36:23',
  'Alma 36:24',
  'Alma 36:25',
  'Alma 36:26',
  'Alma 36:27',
  'Alma 36:28',
  'Alma 36:29',
  'Alma 36:30',
  'Alma 37:1',
  'Alma 37:2',
  'Alma 37:3',
  'Alma 37:4',
  'Alma 37:5',
  'Alma 37:6',
  'Alma 37:7',
  'Alma 37:8',
  'Alma 37:9',
  'Alma 37:10',
  'Alma 37:11',
  'Alma 37:12',
  'Alma 37:13',
  'Alma 37:14',
  'Alma 37:15',
  'Alma 37:16',
  'Alma 37:17',
  'Alma 37:18',
  'Alma 37:19',
  'Alma 37:20',
  'Alma 37:21',
  'Alma 37:22',
  'Alma 37:23',
  'Alma 37:24',
  'Alma 37:25',
  'Alma 37:26',
  'Alma 37:27',
  'Alma 37:28',
  'Alma 37:29',
  'Alma 37:30',
  'Alma 37:31',
  'Alma 37:32',
  'Alma 37:33',
  'Alma 37:34',
  'Alma 37:35',
  'Alma 37:36',
  'Alma 37:37',
  'Alma 37:38',
  'Alma 37:39',
  'Alma 37:40',
  'Alma 37:41',
  'Alma 37:42',
  'Alma 37:43',
  'Alma 37:44',
  'Alma 37:45',
  'Alma 37:46',
  'Alma 37:47',
  'Alma 38:1',
  'Alma 38:2',
  'Alma 38:3',
  'Alma 38:4',
  'Alma 38:5',
  'Alma 38:6',
  'Alma 38:7',
  'Alma 38:8',
  'Alma 38:9',
  'Alma 38:10',
  'Alma 38:11',
  'Alma 38:12',
  'Alma 38:13',
  'Alma 38:14',
  'Alma 38:15',
  'Alma 39:1',
  'Alma 39:2',
  'Alma 39:3',
  'Alma 39:4',
  'Alma 39:5',
  'Alma 39:6',
  'Alma 39:7',
  'Alma 39:8',
  'Alma 39:9',
  'Alma 39:10',
  'Alma 39:11',
  'Alma 39:12',
  'Alma 39:13',
  'Alma 39:14',
  'Alma 39:15',
  'Alma 39:16',
  'Alma 39:17',
  'Alma 39:18',
  'Alma 39:19',
  'Alma 40:1',
  'Alma 40:2',
  'Alma 40:3',
  'Alma 40:4',
  'Alma 40:5',
  'Alma 40:6',
  'Alma 40:7',
  'Alma 40:8',
  'Alma 40:9',
  'Alma 40:10',
  'Alma 40:11',
  'Alma 40:12',
  'Alma 40:13',
  'Alma 40:14',
  'Alma 40:15',
  'Alma 40:16',
  'Alma 40:17',
  'Alma 40:18',
  'Alma 40:19',
  'Alma 40:20',
  'Alma 40:21',
  'Alma 40:22',
  'Alma 40:23',
  'Alma 40:24',
  'Alma 40:25',
  'Alma 40:26',
  'Alma 41:1',
  'Alma 41:2',
  'Alma 41:3',
  'Alma 41:4',
  'Alma 41:5',
  'Alma 41:6',
  'Alma 41:7',
  'Alma 41:8',
  'Alma 41:9',
  'Alma 41:10',
  'Alma 41:11',
  'Alma 41:12',
  'Alma 41:13',
  'Alma 41:14',
  'Alma 41:15',
  'Alma 42:1',
  'Alma 42:2',
  'Alma 42:3',
  'Alma 42:4',
  'Alma 42:5',
  'Alma 42:6',
  'Alma 42:7',
  'Alma 42:8',
  'Alma 42:9',
  'Alma 42:10',
  'Alma 42:11',
  'Alma 42:12',
  'Alma 42:13',
  'Alma 42:14',
  'Alma 42:15',
  'Alma 42:16',
  'Alma 42:17',
  'Alma 42:18',
  'Alma 42:19',
  'Alma 42:20',
  'Alma 42:21',
  'Alma 42:22',
  'Alma 42:23',
  'Alma 42:24',
  'Alma 42:25',
  'Alma 42:26',
  'Alma 42:27',
  'Alma 42:28',
  'Alma 42:29',
  'Alma 42:30',
  'Alma 42:31',
  'Alma 43:1',
  'Alma 43:2',
  'Alma 43:3',
  'Alma 43:4',
  'Alma 43:5',
  'Alma 43:6',
  'Alma 43:7',
  'Alma 43:8',
  'Alma 43:9',
  'Alma 43:10',
  'Alma 43:11',
  'Alma 43:12',
  'Alma 43:13',
  'Alma 43:14',
  'Alma 43:15',
  'Alma 43:16',
  'Alma 43:17',
  'Alma 43:18',
  'Alma 43:19',
  'Alma 43:20',
  'Alma 43:21',
  'Alma 43:22',
  'Alma 43:23',
  'Alma 43:24',
  'Alma 43:25',
  'Alma 43:26',
  'Alma 43:27',
  'Alma 43:28',
  'Alma 43:29',
  'Alma 43:30',
  'Alma 43:31',
  'Alma 43:32',
  'Alma 43:33',
  'Alma 43:34',
  'Alma 43:35',
  'Alma 43:36',
  'Alma 43:37',
  'Alma 43:38',
  'Alma 43:39',
  'Alma 43:40',
  'Alma 43:41',
  'Alma 43:42',
  'Alma 43:43',
  'Alma 43:44',
  'Alma 43:45',
  'Alma 43:46',
  'Alma 43:47',
  'Alma 43:48',
  'Alma 43:49',
  'Alma 43:50',
  'Alma 43:51',
  'Alma 43:52',
  'Alma 43:53',
  'Alma 43:54',
  'Alma 44:1',
  'Alma 44:2',
  'Alma 44:3',
  'Alma 44:4',
  'Alma 44:5',
  'Alma 44:6',
  'Alma 44:7',
  'Alma 44:8',
  'Alma 44:9',
  'Alma 44:10',
  'Alma 44:11',
  'Alma 44:12',
  'Alma 44:13',
  'Alma 44:14',
  'Alma 44:15',
  'Alma 44:16',
  'Alma 44:17',
  'Alma 44:18',
  'Alma 44:19',
  'Alma 44:20',
  'Alma 44:21',
  'Alma 44:22',
  'Alma 44:23',
  'Alma 44:24',
  'Alma 45:1',
  'Alma 45:2',
  'Alma 45:3',
  'Alma 45:4',
  'Alma 45:5',
  'Alma 45:6',
  'Alma 45:7',
  'Alma 45:8',
  'Alma 45:9',
  'Alma 45:10',
  'Alma 45:11',
  'Alma 45:12',
  'Alma 45:13',
  'Alma 45:14',
  'Alma 45:15',
  'Alma 45:16',
  'Alma 45:17',
  'Alma 45:18',
  'Alma 45:19',
  'Alma 45:20',
  'Alma 45:21',
  'Alma 45:22',
  'Alma 45:23',
  'Alma 45:24',
  'Alma 46:1',
  'Alma 46:2',
  'Alma 46:3',
  'Alma 46:4',
  'Alma 46:5',
  'Alma 46:6',
  'Alma 46:7',
  'Alma 46:8',
  'Alma 46:9',
  'Alma 46:10',
  'Alma 46:11',
  'Alma 46:12',
  'Alma 46:13',
  'Alma 46:14',
  'Alma 46:15',
  'Alma 46:16',
  'Alma 46:17',
  'Alma 46:18',
  'Alma 46:19',
  'Alma 46:20',
  'Alma 46:21',
  'Alma 46:22',
  'Alma 46:23',
  'Alma 46:24',
  'Alma 46:25',
  'Alma 46:26',
  'Alma 46:27',
  'Alma 46:28',
  'Alma 46:29',
  'Alma 46:30',
  'Alma 46:31',
  'Alma 46:32',
  'Alma 46:33',
  'Alma 46:34',
  'Alma 46:35',
  'Alma 46:36',
  'Alma 46:37',
  'Alma 46:38',
  'Alma 46:39',
  'Alma 46:40',
  'Alma 46:41',
  'Alma 47:1',
  'Alma 47:2',
  'Alma 47:3',
  'Alma 47:4',
  'Alma 47:5',
  'Alma 47:6',
  'Alma 47:7',
  'Alma 47:8',
  'Alma 47:9',
  'Alma 47:10',
  'Alma 47:11',
  'Alma 47:12',
  'Alma 47:13',
  'Alma 47:14',
  'Alma 47:15',
  'Alma 47:16',
  'Alma 47:17',
  'Alma 47:18',
  'Alma 47:19',
  'Alma 47:20',
  'Alma 47:21',
  'Alma 47:22',
  'Alma 47:23',
  'Alma 47:24',
  'Alma 47:25',
  'Alma 47:26',
  'Alma 47:27',
  'Alma 47:28',
  'Alma 47:29',
  'Alma 47:30',
  'Alma 47:31',
  'Alma 47:32',
  'Alma 47:33',
  'Alma 47:34',
  'Alma 47:35',
  'Alma 47:36',
  'Alma 48:1',
  'Alma 48:2',
  'Alma 48:3',
  'Alma 48:4',
  'Alma 48:5',
  'Alma 48:6',
  'Alma 48:7',
  'Alma 48:8',
  'Alma 48:9',
  'Alma 48:10',
  'Alma 48:11',
  'Alma 48:12',
  'Alma 48:13',
  'Alma 48:14',
  'Alma 48:15',
  'Alma 48:16',
  'Alma 48:17',
  'Alma 48:18',
  'Alma 48:19',
  'Alma 48:20',
  'Alma 48:21',
  'Alma 48:22',
  'Alma 48:23',
  'Alma 48:24',
  'Alma 48:25',
  'Alma 49:1',
  'Alma 49:2',
  'Alma 49:3',
  'Alma 49:4',
  'Alma 49:5',
  'Alma 49:6',
  'Alma 49:7',
  'Alma 49:8',
  'Alma 49:9',
  'Alma 49:10',
  'Alma 49:11',
  'Alma 49:12',
  'Alma 49:13',
  'Alma 49:14',
  'Alma 49:15',
  'Alma 49:16',
  'Alma 49:17',
  'Alma 49:18',
  'Alma 49:19',
  'Alma 49:20',
  'Alma 49:21',
  'Alma 49:22',
  'Alma 49:23',
  'Alma 49:24',
  'Alma 49:25',
  'Alma 49:26',
  'Alma 49:27',
  'Alma 49:28',
  'Alma 49:29',
  'Alma 49:30',
  'Alma 50:1',
  'Alma 50:2',
  'Alma 50:3',
  'Alma 50:4',
  'Alma 50:5',
  'Alma 50:6',
  'Alma 50:7',
  'Alma 50:8',
  'Alma 50:9',
  'Alma 50:10',
  'Alma 50:11',
  'Alma 50:12',
  'Alma 50:13',
  'Alma 50:14',
  'Alma 50:15',
  'Alma 50:16',
  'Alma 50:17',
  'Alma 50:18',
  'Alma 50:19',
  'Alma 50:20',
  'Alma 50:21',
  'Alma 50:22',
  'Alma 50:23',
  'Alma 50:24',
  'Alma 50:25',
  'Alma 50:26',
  'Alma 50:27',
  'Alma 50:28',
  'Alma 50:29',
  'Alma 50:30',
  'Alma 50:31',
  'Alma 50:32',
  'Alma 50:33',
  'Alma 50:34',
  'Alma 50:35',
  'Alma 50:36',
  'Alma 50:37',
  'Alma 50:38',
  'Alma 50:39',
  'Alma 50:40',
  'Alma 51:1',
  'Alma 51:2',
  'Alma 51:3',
  'Alma 51:4',
  'Alma 51:5',
  'Alma 51:6',
  'Alma 51:7',
  'Alma 51:8',
  'Alma 51:9',
  'Alma 51:10',
  'Alma 51:11',
  'Alma 51:12',
  'Alma 51:13',
  'Alma 51:14',
  'Alma 51:15',
  'Alma 51:16',
  'Alma 51:17',
  'Alma 51:18',
  'Alma 51:19',
  'Alma 51:20',
  'Alma 51:21',
  'Alma 51:22',
  'Alma 51:23',
  'Alma 51:24',
  'Alma 51:25',
  'Alma 51:26',
  'Alma 51:27',
  'Alma 51:28',
  'Alma 51:29',
  'Alma 51:30',
  'Alma 51:31',
  'Alma 51:32',
  'Alma 51:33',
  'Alma 51:34',
  'Alma 51:35',
  'Alma 51:36',
  'Alma 51:37',
  'Alma 52:1',
  'Alma 52:2',
  'Alma 52:3',
  'Alma 52:4',
  'Alma 52:5',
  'Alma 52:6',
  'Alma 52:7',
  'Alma 52:8',
  'Alma 52:9',
  'Alma 52:10',
  'Alma 52:11',
  'Alma 52:12',
  'Alma 52:13',
  'Alma 52:14',
  'Alma 52:15',
  'Alma 52:16',
  'Alma 52:17',
  'Alma 52:18',
  'Alma 52:19',
  'Alma 52:20',
  'Alma 52:21',
  'Alma 52:22',
  'Alma 52:23',
  'Alma 52:24',
  'Alma 52:25',
  'Alma 52:26',
  'Alma 52:27',
  'Alma 52:28',
  'Alma 52:29',
  'Alma 52:30',
  'Alma 52:31',
  'Alma 52:32',
  'Alma 52:33',
  'Alma 52:34',
  'Alma 52:35',
  'Alma 52:36',
  'Alma 52:37',
  'Alma 52:38',
  'Alma 52:39',
  'Alma 52:40',
  'Alma 53:1',
  'Alma 53:2',
  'Alma 53:3',
  'Alma 53:4',
  'Alma 53:5',
  'Alma 53:6',
  'Alma 53:7',
  'Alma 53:8',
  'Alma 53:9',
  'Alma 53:10',
  'Alma 53:11',
  'Alma 53:12',
  'Alma 53:13',
  'Alma 53:14',
  'Alma 53:15',
  'Alma 53:16',
  'Alma 53:17',
  'Alma 53:18',
  'Alma 53:19',
  'Alma 53:20',
  'Alma 53:21',
  'Alma 53:22',
  'Alma 53:23',
  'Alma 54:1',
  'Alma 54:2',
  'Alma 54:3',
  'Alma 54:4',
  'Alma 54:5',
  'Alma 54:6',
  'Alma 54:7',
  'Alma 54:8',
  'Alma 54:9',
  'Alma 54:10',
  'Alma 54:11',
  'Alma 54:12',
  'Alma 54:13',
  'Alma 54:14',
  'Alma 54:15',
  'Alma 54:16',
  'Alma 54:17',
  'Alma 54:18',
  'Alma 54:19',
  'Alma 54:20',
  'Alma 54:21',
  'Alma 54:22',
  'Alma 54:23',
  'Alma 54:24',
  'Alma 55:1',
  'Alma 55:2',
  'Alma 55:3',
  'Alma 55:4',
  'Alma 55:5',
  'Alma 55:6',
  'Alma 55:7',
  'Alma 55:8',
  'Alma 55:9',
  'Alma 55:10',
  'Alma 55:11',
  'Alma 55:12',
  'Alma 55:13',
  'Alma 55:14',
  'Alma 55:15',
  'Alma 55:16',
  'Alma 55:17',
  'Alma 55:18',
  'Alma 55:19',
  'Alma 55:20',
  'Alma 55:21',
  'Alma 55:22',
  'Alma 55:23',
  'Alma 55:24',
  'Alma 55:25',
  'Alma 55:26',
  'Alma 55:27',
  'Alma 55:28',
  'Alma 55:29',
  'Alma 55:30',
  'Alma 55:31',
  'Alma 55:32',
  'Alma 55:33',
  'Alma 55:34',
  'Alma 55:35',
  'Alma 56:1',
  'Alma 56:2',
  'Alma 56:3',
  'Alma 56:4',
  'Alma 56:5',
  'Alma 56:6',
  'Alma 56:7',
  'Alma 56:8',
  'Alma 56:9',
  'Alma 56:10',
  'Alma 56:11',
  'Alma 56:12',
  'Alma 56:13',
  'Alma 56:14',
  'Alma 56:15',
  'Alma 56:16',
  'Alma 56:17',
  'Alma 56:18',
  'Alma 56:19',
  'Alma 56:20',
  'Alma 56:21',
  'Alma 56:22',
  'Alma 56:23',
  'Alma 56:24',
  'Alma 56:25',
  'Alma 56:26',
  'Alma 56:27',
  'Alma 56:28',
  'Alma 56:29',
  'Alma 56:30',
  'Alma 56:31',
  'Alma 56:32',
  'Alma 56:33',
  'Alma 56:34',
  'Alma 56:35',
  'Alma 56:36',
  'Alma 56:37',
  'Alma 56:38',
  'Alma 56:39',
  'Alma 56:40',
  'Alma 56:41',
  'Alma 56:42',
  'Alma 56:43',
  'Alma 56:44',
  'Alma 56:45',
  'Alma 56:46',
  'Alma 56:47',
  'Alma 56:48',
  'Alma 56:49',
  'Alma 56:50',
  'Alma 56:51',
  'Alma 56:52',
  'Alma 56:53',
  'Alma 56:54',
  'Alma 56:55',
  'Alma 56:56',
  'Alma 56:57',
  'Alma 57:1',
  'Alma 57:2',
  'Alma 57:3',
  'Alma 57:4',
  'Alma 57:5',
  'Alma 57:6',
  'Alma 57:7',
  'Alma 57:8',
  'Alma 57:9',
  'Alma 57:10',
  'Alma 57:11',
  'Alma 57:12',
  'Alma 57:13',
  'Alma 57:14',
  'Alma 57:15',
  'Alma 57:16',
  'Alma 57:17',
  'Alma 57:18',
  'Alma 57:19',
  'Alma 57:20',
  'Alma 57:21',
  'Alma 57:22',
  'Alma 57:23',
  'Alma 57:24',
  'Alma 57:25',
  'Alma 57:26',
  'Alma 57:27',
  'Alma 57:28',
  'Alma 57:29',
  'Alma 57:30',
  'Alma 57:31',
  'Alma 57:32',
  'Alma 57:33',
  'Alma 57:34',
  'Alma 57:35',
  'Alma 57:36',
  'Alma 58:1',
  'Alma 58:2',
  'Alma 58:3',
  'Alma 58:4',
  'Alma 58:5',
  'Alma 58:6',
  'Alma 58:7',
  'Alma 58:8',
  'Alma 58:9',
  'Alma 58:10',
  'Alma 58:11',
  'Alma 58:12',
  'Alma 58:13',
  'Alma 58:14',
  'Alma 58:15',
  'Alma 58:16',
  'Alma 58:17',
  'Alma 58:18',
  'Alma 58:19',
  'Alma 58:20',
  'Alma 58:21',
  'Alma 58:22',
  'Alma 58:23',
  'Alma 58:24',
  'Alma 58:25',
  'Alma 58:26',
  'Alma 58:27',
  'Alma 58:28',
  'Alma 58:29',
  'Alma 58:30',
  'Alma 58:31',
  'Alma 58:32',
  'Alma 58:33',
  'Alma 58:34',
  'Alma 58:35',
  'Alma 58:36',
  'Alma 58:37',
  'Alma 58:38',
  'Alma 58:39',
  'Alma 58:40',
  'Alma 58:41',
  'Alma 59:1',
  'Alma 59:2',
  'Alma 59:3',
  'Alma 59:4',
  'Alma 59:5',
  'Alma 59:6',
  'Alma 59:7',
  'Alma 59:8',
  'Alma 59:9',
  'Alma 59:10',
  'Alma 59:11',
  'Alma 59:12',
  'Alma 59:13',
  'Alma 60:1',
  'Alma 60:2',
  'Alma 60:3',
  'Alma 60:4',
  'Alma 60:5',
  'Alma 60:6',
  'Alma 60:7',
  'Alma 60:8',
  'Alma 60:9',
  'Alma 60:10',
  'Alma 60:11',
  'Alma 60:12',
  'Alma 60:13',
  'Alma 60:14',
  'Alma 60:15',
  'Alma 60:16',
  'Alma 60:17',
  'Alma 60:18',
  'Alma 60:19',
  'Alma 60:20',
  'Alma 60:21',
  'Alma 60:22',
  'Alma 60:23',
  'Alma 60:24',
  'Alma 60:25',
  'Alma 60:26',
  'Alma 60:27',
  'Alma 60:28',
  'Alma 60:29',
  'Alma 60:30',
  'Alma 60:31',
  'Alma 60:32',
  'Alma 60:33',
  'Alma 60:34',
  'Alma 60:35',
  'Alma 60:36',
  'Alma 61:1',
  'Alma 61:2',
  'Alma 61:3',
  'Alma 61:4',
  'Alma 61:5',
  'Alma 61:6',
  'Alma 61:7',
  'Alma 61:8',
  'Alma 61:9',
  'Alma 61:10',
  'Alma 61:11',
  'Alma 61:12',
  'Alma 61:13',
  'Alma 61:14',
  'Alma 61:15',
  'Alma 61:16',
  'Alma 61:17',
  'Alma 61:18',
  'Alma 61:19',
  'Alma 61:20',
  'Alma 61:21',
  'Alma 62:1',
  'Alma 62:2',
  'Alma 62:3',
  'Alma 62:4',
  'Alma 62:5',
  'Alma 62:6',
  'Alma 62:7',
  'Alma 62:8',
  'Alma 62:9',
  'Alma 62:10',
  'Alma 62:11',
  'Alma 62:12',
  'Alma 62:13',
  'Alma 62:14',
  'Alma 62:15',
  'Alma 62:16',
  'Alma 62:17',
  'Alma 62:18',
  'Alma 62:19',
  'Alma 62:20',
  'Alma 62:21',
  'Alma 62:22',
  'Alma 62:23',
  'Alma 62:24',
  'Alma 62:25',
  'Alma 62:26',
  'Alma 62:27',
  'Alma 62:28',
  'Alma 62:29',
  'Alma 62:30',
  'Alma 62:31',
  'Alma 62:32',
  'Alma 62:33',
  'Alma 62:34',
  'Alma 62:35',
  'Alma 62:36',
  'Alma 62:37',
  'Alma 62:38',
  'Alma 62:39',
  'Alma 62:40',
  'Alma 62:41',
  'Alma 62:42',
  'Alma 62:43',
  'Alma 62:44',
  'Alma 62:45',
  'Alma 62:46',
  'Alma 62:47',
  'Alma 62:48',
  'Alma 62:49',
  'Alma 62:50',
  'Alma 62:51',
  'Alma 62:52',
  'Alma 63:1',
  'Alma 63:2',
  'Alma 63:3',
  'Alma 63:4',
  'Alma 63:5',
  'Alma 63:6',
  'Alma 63:7',
  'Alma 63:8',
  'Alma 63:9',
  'Alma 63:10',
  'Alma 63:11',
  'Alma 63:12',
  'Alma 63:13',
  'Alma 63:14',
  'Alma 63:15',
  'Alma 63:16',
  'Alma 63:17',
  'Helaman 1:1',
  'Helaman 1:2',
  'Helaman 1:3',
  'Helaman 1:4',
  'Helaman 1:5',
  'Helaman 1:6',
  'Helaman 1:7',
  'Helaman 1:8',
  'Helaman 1:9',
  'Helaman 1:10',
  'Helaman 1:11',
  'Helaman 1:12',
  'Helaman 1:13',
  'Helaman 1:14',
  'Helaman 1:15',
  'Helaman 1:16',
  'Helaman 1:17',
  'Helaman 1:18',
  'Helaman 1:19',
  'Helaman 1:20',
  'Helaman 1:21',
  'Helaman 1:22',
  'Helaman 1:23',
  'Helaman 1:24',
  'Helaman 1:25',
  'Helaman 1:26',
  'Helaman 1:27',
  'Helaman 1:28',
  'Helaman 1:29',
  'Helaman 1:30',
  'Helaman 1:31',
  'Helaman 1:32',
  'Helaman 1:33',
  'Helaman 1:34',
  'Helaman 2:1',
  'Helaman 2:2',
  'Helaman 2:3',
  'Helaman 2:4',
  'Helaman 2:5',
  'Helaman 2:6',
  'Helaman 2:7',
  'Helaman 2:8',
  'Helaman 2:9',
  'Helaman 2:10',
  'Helaman 2:11',
  'Helaman 2:12',
  'Helaman 2:13',
  'Helaman 2:14',
  'Helaman 3:1',
  'Helaman 3:2',
  'Helaman 3:3',
  'Helaman 3:4',
  'Helaman 3:5',
  'Helaman 3:6',
  'Helaman 3:7',
  'Helaman 3:8',
  'Helaman 3:9',
  'Helaman 3:10',
  'Helaman 3:11',
  'Helaman 3:12',
  'Helaman 3:13',
  'Helaman 3:14',
  'Helaman 3:15',
  'Helaman 3:16',
  'Helaman 3:17',
  'Helaman 3:18',
  'Helaman 3:19',
  'Helaman 3:20',
  'Helaman 3:21',
  'Helaman 3:22',
  'Helaman 3:23',
  'Helaman 3:24',
  'Helaman 3:25',
  'Helaman 3:26',
  'Helaman 3:27',
  'Helaman 3:28',
  'Helaman 3:29',
  'Helaman 3:30',
  'Helaman 3:31',
  'Helaman 3:32',
  'Helaman 3:33',
  'Helaman 3:34',
  'Helaman 3:35',
  'Helaman 3:36',
  'Helaman 3:37',
  'Helaman 4:1',
  'Helaman 4:2',
  'Helaman 4:3',
  'Helaman 4:4',
  'Helaman 4:5',
  'Helaman 4:6',
  'Helaman 4:7',
  'Helaman 4:8',
  'Helaman 4:9',
  'Helaman 4:10',
  'Helaman 4:11',
  'Helaman 4:12',
  'Helaman 4:13',
  'Helaman 4:14',
  'Helaman 4:15',
  'Helaman 4:16',
  'Helaman 4:17',
  'Helaman 4:18',
  'Helaman 4:19',
  'Helaman 4:20',
  'Helaman 4:21',
  'Helaman 4:22',
  'Helaman 4:23',
  'Helaman 4:24',
  'Helaman 4:25',
  'Helaman 4:26',
  'Helaman 5:1',
  'Helaman 5:2',
  'Helaman 5:3',
  'Helaman 5:4',
  'Helaman 5:5',
  'Helaman 5:6',
  'Helaman 5:7',
  'Helaman 5:8',
  'Helaman 5:9',
  'Helaman 5:10',
  'Helaman 5:11',
  'Helaman 5:12',
  'Helaman 5:13',
  'Helaman 5:14',
  'Helaman 5:15',
  'Helaman 5:16',
  'Helaman 5:17',
  'Helaman 5:18',
  'Helaman 5:19',
  'Helaman 5:20',
  'Helaman 5:21',
  'Helaman 5:22',
  'Helaman 5:23',
  'Helaman 5:24',
  'Helaman 5:25',
  'Helaman 5:26',
  'Helaman 5:27',
  'Helaman 5:28',
  'Helaman 5:29',
  'Helaman 5:30',
  'Helaman 5:31',
  'Helaman 5:32',
  'Helaman 5:33',
  'Helaman 5:34',
  'Helaman 5:35',
  'Helaman 5:36',
  'Helaman 5:37',
  'Helaman 5:38',
  'Helaman 5:39',
  'Helaman 5:40',
  'Helaman 5:41',
  'Helaman 5:42',
  'Helaman 5:43',
  'Helaman 5:44',
  'Helaman 5:45',
  'Helaman 5:46',
  'Helaman 5:47',
  'Helaman 5:48',
  'Helaman 5:49',
  'Helaman 5:50',
  'Helaman 5:51',
  'Helaman 5:52',
  'Helaman 6:1',
  'Helaman 6:2',
  'Helaman 6:3',
  'Helaman 6:4',
  'Helaman 6:5',
  'Helaman 6:6',
  'Helaman 6:7',
  'Helaman 6:8',
  'Helaman 6:9',
  'Helaman 6:10',
  'Helaman 6:11',
  'Helaman 6:12',
  'Helaman 6:13',
  'Helaman 6:14',
  'Helaman 6:15',
  'Helaman 6:16',
  'Helaman 6:17',
  'Helaman 6:18',
  'Helaman 6:19',
  'Helaman 6:20',
  'Helaman 6:21',
  'Helaman 6:22',
  'Helaman 6:23',
  'Helaman 6:24',
  'Helaman 6:25',
  'Helaman 6:26',
  'Helaman 6:27',
  'Helaman 6:28',
  'Helaman 6:29',
  'Helaman 6:30',
  'Helaman 6:31',
  'Helaman 6:32',
  'Helaman 6:33',
  'Helaman 6:34',
  'Helaman 6:35',
  'Helaman 6:36',
  'Helaman 6:37',
  'Helaman 6:38',
  'Helaman 6:39',
  'Helaman 6:40',
  'Helaman 6:41',
  'Helaman 7:1',
  'Helaman 7:2',
  'Helaman 7:3',
  'Helaman 7:4',
  'Helaman 7:5',
  'Helaman 7:6',
  'Helaman 7:7',
  'Helaman 7:8',
  'Helaman 7:9',
  'Helaman 7:10',
  'Helaman 7:11',
  'Helaman 7:12',
  'Helaman 7:13',
  'Helaman 7:14',
  'Helaman 7:15',
  'Helaman 7:16',
  'Helaman 7:17',
  'Helaman 7:18',
  'Helaman 7:19',
  'Helaman 7:20',
  'Helaman 7:21',
  'Helaman 7:22',
  'Helaman 7:23',
  'Helaman 7:24',
  'Helaman 7:25',
  'Helaman 7:26',
  'Helaman 7:27',
  'Helaman 7:28',
  'Helaman 7:29',
  'Helaman 8:1',
  'Helaman 8:2',
  'Helaman 8:3',
  'Helaman 8:4',
  'Helaman 8:5',
  'Helaman 8:6',
  'Helaman 8:7',
  'Helaman 8:8',
  'Helaman 8:9',
  'Helaman 8:10',
  'Helaman 8:11',
  'Helaman 8:12',
  'Helaman 8:13',
  'Helaman 8:14',
  'Helaman 8:15',
  'Helaman 8:16',
  'Helaman 8:17',
  'Helaman 8:18',
  'Helaman 8:19',
  'Helaman 8:20',
  'Helaman 8:21',
  'Helaman 8:22',
  'Helaman 8:23',
  'Helaman 8:24',
  'Helaman 8:25',
  'Helaman 8:26',
  'Helaman 8:27',
  'Helaman 8:28',
  'Helaman 9:1',
  'Helaman 9:2',
  'Helaman 9:3',
  'Helaman 9:4',
  'Helaman 9:5',
  'Helaman 9:6',
  'Helaman 9:7',
  'Helaman 9:8',
  'Helaman 9:9',
  'Helaman 9:10',
  'Helaman 9:11',
  'Helaman 9:12',
  'Helaman 9:13',
  'Helaman 9:14',
  'Helaman 9:15',
  'Helaman 9:16',
  'Helaman 9:17',
  'Helaman 9:18',
  'Helaman 9:19',
  'Helaman 9:20',
  'Helaman 9:21',
  'Helaman 9:22',
  'Helaman 9:23',
  'Helaman 9:24',
  'Helaman 9:25',
  'Helaman 9:26',
  'Helaman 9:27',
  'Helaman 9:28',
  'Helaman 9:29',
  'Helaman 9:30',
  'Helaman 9:31',
  'Helaman 9:32',
  'Helaman 9:33',
  'Helaman 9:34',
  'Helaman 9:35',
  'Helaman 9:36',
  'Helaman 9:37',
  'Helaman 9:38',
  'Helaman 9:39',
  'Helaman 9:40',
  'Helaman 9:41',
  'Helaman 10:1',
  'Helaman 10:2',
  'Helaman 10:3',
  'Helaman 10:4',
  'Helaman 10:5',
  'Helaman 10:6',
  'Helaman 10:7',
  'Helaman 10:8',
  'Helaman 10:9',
  'Helaman 10:10',
  'Helaman 10:11',
  'Helaman 10:12',
  'Helaman 10:13',
  'Helaman 10:14',
  'Helaman 10:15',
  'Helaman 10:16',
  'Helaman 10:17',
  'Helaman 10:18',
  'Helaman 10:19',
  'Helaman 11:1',
  'Helaman 11:2',
  'Helaman 11:3',
  'Helaman 11:4',
  'Helaman 11:5',
  'Helaman 11:6',
  'Helaman 11:7',
  'Helaman 11:8',
  'Helaman 11:9',
  'Helaman 11:10',
  'Helaman 11:11',
  'Helaman 11:12',
  'Helaman 11:13',
  'Helaman 11:14',
  'Helaman 11:15',
  'Helaman 11:16',
  'Helaman 11:17',
  'Helaman 11:18',
  'Helaman 11:19',
  'Helaman 11:20',
  'Helaman 11:21',
  'Helaman 11:22',
  'Helaman 11:23',
  'Helaman 11:24',
  'Helaman 11:25',
  'Helaman 11:26',
  'Helaman 11:27',
  'Helaman 11:28',
  'Helaman 11:29',
  'Helaman 11:30',
  'Helaman 11:31',
  'Helaman 11:32',
  'Helaman 11:33',
  'Helaman 11:34',
  'Helaman 11:35',
  'Helaman 11:36',
  'Helaman 11:37',
  'Helaman 11:38',
  'Helaman 12:1',
  'Helaman 12:2',
  'Helaman 12:3',
  'Helaman 12:4',
  'Helaman 12:5',
  'Helaman 12:6',
  'Helaman 12:7',
  'Helaman 12:8',
  'Helaman 12:9',
  'Helaman 12:10',
  'Helaman 12:11',
  'Helaman 12:12',
  'Helaman 12:13',
  'Helaman 12:14',
  'Helaman 12:15',
  'Helaman 12:16',
  'Helaman 12:17',
  'Helaman 12:18',
  'Helaman 12:19',
  'Helaman 12:20',
  'Helaman 12:21',
  'Helaman 12:22',
  'Helaman 12:23',
  'Helaman 12:24',
  'Helaman 12:25',
  'Helaman 12:26',
  'Helaman 13:1',
  'Helaman 13:2',
  'Helaman 13:3',
  'Helaman 13:4',
  'Helaman 13:5',
  'Helaman 13:6',
  'Helaman 13:7',
  'Helaman 13:8',
  'Helaman 13:9',
  'Helaman 13:10',
  'Helaman 13:11',
  'Helaman 13:12',
  'Helaman 13:13',
  'Helaman 13:14',
  'Helaman 13:15',
  'Helaman 13:16',
  'Helaman 13:17',
  'Helaman 13:18',
  'Helaman 13:19',
  'Helaman 13:20',
  'Helaman 13:21',
  'Helaman 13:22',
  'Helaman 13:23',
  'Helaman 13:24',
  'Helaman 13:25',
  'Helaman 13:26',
  'Helaman 13:27',
  'Helaman 13:28',
  'Helaman 13:29',
  'Helaman 13:30',
  'Helaman 13:31',
  'Helaman 13:32',
  'Helaman 13:33',
  'Helaman 13:34',
  'Helaman 13:35',
  'Helaman 13:36',
  'Helaman 13:37',
  'Helaman 13:38',
  'Helaman 13:39',
  'Helaman 14:1',
  'Helaman 14:2',
  'Helaman 14:3',
  'Helaman 14:4',
  'Helaman 14:5',
  'Helaman 14:6',
  'Helaman 14:7',
  'Helaman 14:8',
  'Helaman 14:9',
  'Helaman 14:10',
  'Helaman 14:11',
  'Helaman 14:12',
  'Helaman 14:13',
  'Helaman 14:14',
  'Helaman 14:15',
  'Helaman 14:16',
  'Helaman 14:17',
  'Helaman 14:18',
  'Helaman 14:19',
  'Helaman 14:20',
  'Helaman 14:21',
  'Helaman 14:22',
  'Helaman 14:23',
  'Helaman 14:24',
  'Helaman 14:25',
  'Helaman 14:26',
  'Helaman 14:27',
  'Helaman 14:28',
  'Helaman 14:29',
  'Helaman 14:30',
  'Helaman 14:31',
  'Helaman 15:1',
  'Helaman 15:2',
  'Helaman 15:3',
  'Helaman 15:4',
  'Helaman 15:5',
  'Helaman 15:6',
  'Helaman 15:7',
  'Helaman 15:8',
  'Helaman 15:9',
  'Helaman 15:10',
  'Helaman 15:11',
  'Helaman 15:12',
  'Helaman 15:13',
  'Helaman 15:14',
  'Helaman 15:15',
  'Helaman 15:16',
  'Helaman 15:17',
  'Helaman 16:1',
  'Helaman 16:2',
  'Helaman 16:3',
  'Helaman 16:4',
  'Helaman 16:5',
  'Helaman 16:6',
  'Helaman 16:7',
  'Helaman 16:8',
  'Helaman 16:9',
  'Helaman 16:10',
  'Helaman 16:11',
  'Helaman 16:12',
  'Helaman 16:13',
  'Helaman 16:14',
  'Helaman 16:15',
  'Helaman 16:16',
  'Helaman 16:17',
  'Helaman 16:18',
  'Helaman 16:19',
  'Helaman 16:20',
  'Helaman 16:21',
  'Helaman 16:22',
  'Helaman 16:23',
  'Helaman 16:24',
  'Helaman 16:25',
  '3 Nephi 1:1',
  '3 Nephi 1:2',
  '3 Nephi 1:3',
  '3 Nephi 1:4',
  '3 Nephi 1:5',
  '3 Nephi 1:6',
  '3 Nephi 1:7',
  '3 Nephi 1:8',
  '3 Nephi 1:9',
  '3 Nephi 1:10',
  '3 Nephi 1:11',
  '3 Nephi 1:12',
  '3 Nephi 1:13',
  '3 Nephi 1:14',
  '3 Nephi 1:15',
  '3 Nephi 1:16',
  '3 Nephi 1:17',
  '3 Nephi 1:18',
  '3 Nephi 1:19',
  '3 Nephi 1:20',
  '3 Nephi 1:21',
  '3 Nephi 1:22',
  '3 Nephi 1:23',
  '3 Nephi 1:24',
  '3 Nephi 1:25',
  '3 Nephi 1:26',
  '3 Nephi 1:27',
  '3 Nephi 1:28',
  '3 Nephi 1:29',
  '3 Nephi 1:30',
  '3 Nephi 2:1',
  '3 Nephi 2:2',
  '3 Nephi 2:3',
  '3 Nephi 2:4',
  '3 Nephi 2:5',
  '3 Nephi 2:6',
  '3 Nephi 2:7',
  '3 Nephi 2:8',
  '3 Nephi 2:9',
  '3 Nephi 2:10',
  '3 Nephi 2:11',
  '3 Nephi 2:12',
  '3 Nephi 2:13',
  '3 Nephi 2:14',
  '3 Nephi 2:15',
  '3 Nephi 2:16',
  '3 Nephi 2:17',
  '3 Nephi 2:18',
  '3 Nephi 2:19',
  '3 Nephi 3:1',
  '3 Nephi 3:2',
  '3 Nephi 3:3',
  '3 Nephi 3:4',
  '3 Nephi 3:5',
  '3 Nephi 3:6',
  '3 Nephi 3:7',
  '3 Nephi 3:8',
  '3 Nephi 3:9',
  '3 Nephi 3:10',
  '3 Nephi 3:11',
  '3 Nephi 3:12',
  '3 Nephi 3:13',
  '3 Nephi 3:14',
  '3 Nephi 3:15',
  '3 Nephi 3:16',
  '3 Nephi 3:17',
  '3 Nephi 3:18',
  '3 Nephi 3:19',
  '3 Nephi 3:20',
  '3 Nephi 3:21',
  '3 Nephi 3:22',
  '3 Nephi 3:23',
  '3 Nephi 3:24',
  '3 Nephi 3:25',
  '3 Nephi 3:26',
  '3 Nephi 4:1',
  '3 Nephi 4:2',
  '3 Nephi 4:3',
  '3 Nephi 4:4',
  '3 Nephi 4:5',
  '3 Nephi 4:6',
  '3 Nephi 4:7',
  '3 Nephi 4:8',
  '3 Nephi 4:9',
  '3 Nephi 4:10',
  '3 Nephi 4:11',
  '3 Nephi 4:12',
  '3 Nephi 4:13',
  '3 Nephi 4:14',
  '3 Nephi 4:15',
  '3 Nephi 4:16',
  '3 Nephi 4:17',
  '3 Nephi 4:18',
  '3 Nephi 4:19',
  '3 Nephi 4:20',
  '3 Nephi 4:21',
  '3 Nephi 4:22',
  '3 Nephi 4:23',
  '3 Nephi 4:24',
  '3 Nephi 4:25',
  '3 Nephi 4:26',
  '3 Nephi 4:27',
  '3 Nephi 4:28',
  '3 Nephi 4:29',
  '3 Nephi 4:30',
  '3 Nephi 4:31',
  '3 Nephi 4:32',
  '3 Nephi 4:33',
  '3 Nephi 5:1',
  '3 Nephi 5:2',
  '3 Nephi 5:3',
  '3 Nephi 5:4',
  '3 Nephi 5:5',
  '3 Nephi 5:6',
  '3 Nephi 5:7',
  '3 Nephi 5:8',
  '3 Nephi 5:9',
  '3 Nephi 5:10',
  '3 Nephi 5:11',
  '3 Nephi 5:12',
  '3 Nephi 5:13',
  '3 Nephi 5:14',
  '3 Nephi 5:15',
  '3 Nephi 5:16',
  '3 Nephi 5:17',
  '3 Nephi 5:18',
  '3 Nephi 5:19',
  '3 Nephi 5:20',
  '3 Nephi 5:21',
  '3 Nephi 5:22',
  '3 Nephi 5:23',
  '3 Nephi 5:24',
  '3 Nephi 5:25',
  '3 Nephi 5:26',
  '3 Nephi 6:1',
  '3 Nephi 6:2',
  '3 Nephi 6:3',
  '3 Nephi 6:4',
  '3 Nephi 6:5',
  '3 Nephi 6:6',
  '3 Nephi 6:7',
  '3 Nephi 6:8',
  '3 Nephi 6:9',
  '3 Nephi 6:10',
  '3 Nephi 6:11',
  '3 Nephi 6:12',
  '3 Nephi 6:13',
  '3 Nephi 6:14',
  '3 Nephi 6:15',
  '3 Nephi 6:16',
  '3 Nephi 6:17',
  '3 Nephi 6:18',
  '3 Nephi 6:19',
  '3 Nephi 6:20',
  '3 Nephi 6:21',
  '3 Nephi 6:22',
  '3 Nephi 6:23',
  '3 Nephi 6:24',
  '3 Nephi 6:25',
  '3 Nephi 6:26',
  '3 Nephi 6:27',
  '3 Nephi 6:28',
  '3 Nephi 6:29',
  '3 Nephi 6:30',
  '3 Nephi 7:1',
  '3 Nephi 7:2',
  '3 Nephi 7:3',
  '3 Nephi 7:4',
  '3 Nephi 7:5',
  '3 Nephi 7:6',
  '3 Nephi 7:7',
  '3 Nephi 7:8',
  '3 Nephi 7:9',
  '3 Nephi 7:10',
  '3 Nephi 7:11',
  '3 Nephi 7:12',
  '3 Nephi 7:13',
  '3 Nephi 7:14',
  '3 Nephi 7:15',
  '3 Nephi 7:16',
  '3 Nephi 7:17',
  '3 Nephi 7:18',
  '3 Nephi 7:19',
  '3 Nephi 7:20',
  '3 Nephi 7:21',
  '3 Nephi 7:22',
  '3 Nephi 7:23',
  '3 Nephi 7:24',
  '3 Nephi 7:25',
  '3 Nephi 7:26',
  '3 Nephi 8:1',
  '3 Nephi 8:2',
  '3 Nephi 8:3',
  '3 Nephi 8:4',
  '3 Nephi 8:5',
  '3 Nephi 8:6',
  '3 Nephi 8:7',
  '3 Nephi 8:8',
  '3 Nephi 8:9',
  '3 Nephi 8:10',
  '3 Nephi 8:11',
  '3 Nephi 8:12',
  '3 Nephi 8:13',
  '3 Nephi 8:14',
  '3 Nephi 8:15',
  '3 Nephi 8:16',
  '3 Nephi 8:17',
  '3 Nephi 8:18',
  '3 Nephi 8:19',
  '3 Nephi 8:20',
  '3 Nephi 8:21',
  '3 Nephi 8:22',
  '3 Nephi 8:23',
  '3 Nephi 8:24',
  '3 Nephi 8:25',
  '3 Nephi 9:1',
  '3 Nephi 9:2',
  '3 Nephi 9:3',
  '3 Nephi 9:4',
  '3 Nephi 9:5',
  '3 Nephi 9:6',
  '3 Nephi 9:7',
  '3 Nephi 9:8',
  '3 Nephi 9:9',
  '3 Nephi 9:10',
  '3 Nephi 9:11',
  '3 Nephi 9:12',
  '3 Nephi 9:13',
  '3 Nephi 9:14',
  '3 Nephi 9:15',
  '3 Nephi 9:16',
  '3 Nephi 9:17',
  '3 Nephi 9:18',
  '3 Nephi 9:19',
  '3 Nephi 9:20',
  '3 Nephi 9:21',
  '3 Nephi 9:22',
  '3 Nephi 10:1',
  '3 Nephi 10:2',
  '3 Nephi 10:3',
  '3 Nephi 10:4',
  '3 Nephi 10:5',
  '3 Nephi 10:6',
  '3 Nephi 10:7',
  '3 Nephi 10:8',
  '3 Nephi 10:9',
  '3 Nephi 10:10',
  '3 Nephi 10:11',
  '3 Nephi 10:12',
  '3 Nephi 10:13',
  '3 Nephi 10:14',
  '3 Nephi 10:15',
  '3 Nephi 10:16',
  '3 Nephi 10:17',
  '3 Nephi 10:18',
  '3 Nephi 10:19',
  '3 Nephi 11:1',
  '3 Nephi 11:2',
  '3 Nephi 11:3',
  '3 Nephi 11:4',
  '3 Nephi 11:5',
  '3 Nephi 11:6',
  '3 Nephi 11:7',
  '3 Nephi 11:8',
  '3 Nephi 11:9',
  '3 Nephi 11:10',
  '3 Nephi 11:11',
  '3 Nephi 11:12',
  '3 Nephi 11:13',
  '3 Nephi 11:14',
  '3 Nephi 11:15',
  '3 Nephi 11:16',
  '3 Nephi 11:17',
  '3 Nephi 11:18',
  '3 Nephi 11:19',
  '3 Nephi 11:20',
  '3 Nephi 11:21',
  '3 Nephi 11:22',
  '3 Nephi 11:23',
  '3 Nephi 11:24',
  '3 Nephi 11:25',
  '3 Nephi 11:26',
  '3 Nephi 11:27',
  '3 Nephi 11:28',
  '3 Nephi 11:29',
  '3 Nephi 11:30',
  '3 Nephi 11:31',
  '3 Nephi 11:32',
  '3 Nephi 11:33',
  '3 Nephi 11:34',
  '3 Nephi 11:35',
  '3 Nephi 11:36',
  '3 Nephi 11:37',
  '3 Nephi 11:38',
  '3 Nephi 11:39',
  '3 Nephi 11:40',
  '3 Nephi 11:41',
  '3 Nephi 12:1',
  '3 Nephi 12:2',
  '3 Nephi 12:3',
  '3 Nephi 12:4',
  '3 Nephi 12:5',
  '3 Nephi 12:6',
  '3 Nephi 12:7',
  '3 Nephi 12:8',
  '3 Nephi 12:9',
  '3 Nephi 12:10',
  '3 Nephi 12:11',
  '3 Nephi 12:12',
  '3 Nephi 12:13',
  '3 Nephi 12:14',
  '3 Nephi 12:15',
  '3 Nephi 12:16',
  '3 Nephi 12:17',
  '3 Nephi 12:18',
  '3 Nephi 12:19',
  '3 Nephi 12:20',
  '3 Nephi 12:21',
  '3 Nephi 12:22',
  '3 Nephi 12:23',
  '3 Nephi 12:24',
  '3 Nephi 12:25',
  '3 Nephi 12:26',
  '3 Nephi 12:27',
  '3 Nephi 12:28',
  '3 Nephi 12:29',
  '3 Nephi 12:30',
  '3 Nephi 12:31',
  '3 Nephi 12:32',
  '3 Nephi 12:33',
  '3 Nephi 12:34',
  '3 Nephi 12:35',
  '3 Nephi 12:36',
  '3 Nephi 12:37',
  '3 Nephi 12:38',
  '3 Nephi 12:39',
  '3 Nephi 12:40',
  '3 Nephi 12:41',
  '3 Nephi 12:42',
  '3 Nephi 12:43',
  '3 Nephi 12:44',
  '3 Nephi 12:45',
  '3 Nephi 12:46',
  '3 Nephi 12:47',
  '3 Nephi 12:48',
  '3 Nephi 13:1',
  '3 Nephi 13:2',
  '3 Nephi 13:3',
  '3 Nephi 13:4',
  '3 Nephi 13:5',
  '3 Nephi 13:6',
  '3 Nephi 13:7',
  '3 Nephi 13:8',
  '3 Nephi 13:9',
  '3 Nephi 13:10',
  '3 Nephi 13:11',
  '3 Nephi 13:12',
  '3 Nephi 13:13',
  '3 Nephi 13:14',
  '3 Nephi 13:15',
  '3 Nephi 13:16',
  '3 Nephi 13:17',
  '3 Nephi 13:18',
  '3 Nephi 13:19',
  '3 Nephi 13:20',
  '3 Nephi 13:21',
  '3 Nephi 13:22',
  '3 Nephi 13:23',
  '3 Nephi 13:24',
  '3 Nephi 13:25',
  '3 Nephi 13:26',
  '3 Nephi 13:27',
  '3 Nephi 13:28',
  '3 Nephi 13:29',
  '3 Nephi 13:30',
  '3 Nephi 13:31',
  '3 Nephi 13:32',
  '3 Nephi 13:33',
  '3 Nephi 13:34',
  '3 Nephi 14:1',
  '3 Nephi 14:2',
  '3 Nephi 14:3',
  '3 Nephi 14:4',
  '3 Nephi 14:5',
  '3 Nephi 14:6',
  '3 Nephi 14:7',
  '3 Nephi 14:8',
  '3 Nephi 14:9',
  '3 Nephi 14:10',
  '3 Nephi 14:11',
  '3 Nephi 14:12',
  '3 Nephi 14:13',
  '3 Nephi 14:14',
  '3 Nephi 14:15',
  '3 Nephi 14:16',
  '3 Nephi 14:17',
  '3 Nephi 14:18',
  '3 Nephi 14:19',
  '3 Nephi 14:20',
  '3 Nephi 14:21',
  '3 Nephi 14:22',
  '3 Nephi 14:23',
  '3 Nephi 14:24',
  '3 Nephi 14:25',
  '3 Nephi 14:26',
  '3 Nephi 14:27',
  '3 Nephi 15:1',
  '3 Nephi 15:2',
  '3 Nephi 15:3',
  '3 Nephi 15:4',
  '3 Nephi 15:5',
  '3 Nephi 15:6',
  '3 Nephi 15:7',
  '3 Nephi 15:8',
  '3 Nephi 15:9',
  '3 Nephi 15:10',
  '3 Nephi 15:11',
  '3 Nephi 15:12',
  '3 Nephi 15:13',
  '3 Nephi 15:14',
  '3 Nephi 15:15',
  '3 Nephi 15:16',
  '3 Nephi 15:17',
  '3 Nephi 15:18',
  '3 Nephi 15:19',
  '3 Nephi 15:20',
  '3 Nephi 15:21',
  '3 Nephi 15:22',
  '3 Nephi 15:23',
  '3 Nephi 15:24',
  '3 Nephi 16:1',
  '3 Nephi 16:2',
  '3 Nephi 16:3',
  '3 Nephi 16:4',
  '3 Nephi 16:5',
  '3 Nephi 16:6',
  '3 Nephi 16:7',
  '3 Nephi 16:8',
  '3 Nephi 16:9',
  '3 Nephi 16:10',
  '3 Nephi 16:11',
  '3 Nephi 16:12',
  '3 Nephi 16:13',
  '3 Nephi 16:14',
  '3 Nephi 16:15',
  '3 Nephi 16:16',
  '3 Nephi 16:17',
  '3 Nephi 16:18',
  '3 Nephi 16:19',
  '3 Nephi 16:20',
  '3 Nephi 17:1',
  '3 Nephi 17:2',
  '3 Nephi 17:3',
  '3 Nephi 17:4',
  '3 Nephi 17:5',
  '3 Nephi 17:6',
  '3 Nephi 17:7',
  '3 Nephi 17:8',
  '3 Nephi 17:9',
  '3 Nephi 17:10',
  '3 Nephi 17:11',
  '3 Nephi 17:12',
  '3 Nephi 17:13',
  '3 Nephi 17:14',
  '3 Nephi 17:15',
  '3 Nephi 17:16',
  '3 Nephi 17:17',
  '3 Nephi 17:18',
  '3 Nephi 17:19',
  '3 Nephi 17:20',
  '3 Nephi 17:21',
  '3 Nephi 17:22',
  '3 Nephi 17:23',
  '3 Nephi 17:24',
  '3 Nephi 17:25',
  '3 Nephi 18:1',
  '3 Nephi 18:2',
  '3 Nephi 18:3',
  '3 Nephi 18:4',
  '3 Nephi 18:5',
  '3 Nephi 18:6',
  '3 Nephi 18:7',
  '3 Nephi 18:8',
  '3 Nephi 18:9',
  '3 Nephi 18:10',
  '3 Nephi 18:11',
  '3 Nephi 18:12',
  '3 Nephi 18:13',
  '3 Nephi 18:14',
  '3 Nephi 18:15',
  '3 Nephi 18:16',
  '3 Nephi 18:17',
  '3 Nephi 18:18',
  '3 Nephi 18:19',
  '3 Nephi 18:20',
  '3 Nephi 18:21',
  '3 Nephi 18:22',
  '3 Nephi 18:23',
  '3 Nephi 18:24',
  '3 Nephi 18:25',
  '3 Nephi 18:26',
  '3 Nephi 18:27',
  '3 Nephi 18:28',
  '3 Nephi 18:29',
  '3 Nephi 18:30',
  '3 Nephi 18:31',
  '3 Nephi 18:32',
  '3 Nephi 18:33',
  '3 Nephi 18:34',
  '3 Nephi 18:35',
  '3 Nephi 18:36',
  '3 Nephi 18:37',
  '3 Nephi 18:38',
  '3 Nephi 18:39',
  '3 Nephi 19:1',
  '3 Nephi 19:2',
  '3 Nephi 19:3',
  '3 Nephi 19:4',
  '3 Nephi 19:5',
  '3 Nephi 19:6',
  '3 Nephi 19:7',
  '3 Nephi 19:8',
  '3 Nephi 19:9',
  '3 Nephi 19:10',
  '3 Nephi 19:11',
  '3 Nephi 19:12',
  '3 Nephi 19:13',
  '3 Nephi 19:14',
  '3 Nephi 19:15',
  '3 Nephi 19:16',
  '3 Nephi 19:17',
  '3 Nephi 19:18',
  '3 Nephi 19:19',
  '3 Nephi 19:20',
  '3 Nephi 19:21',
  '3 Nephi 19:22',
  '3 Nephi 19:23',
  '3 Nephi 19:24',
  '3 Nephi 19:25',
  '3 Nephi 19:26',
  '3 Nephi 19:27',
  '3 Nephi 19:28',
  '3 Nephi 19:29',
  '3 Nephi 19:30',
  '3 Nephi 19:31',
  '3 Nephi 19:32',
  '3 Nephi 19:33',
  '3 Nephi 19:34',
  '3 Nephi 19:35',
  '3 Nephi 19:36',
  '3 Nephi 20:1',
  '3 Nephi 20:2',
  '3 Nephi 20:3',
  '3 Nephi 20:4',
  '3 Nephi 20:5',
  '3 Nephi 20:6',
  '3 Nephi 20:7',
  '3 Nephi 20:8',
  '3 Nephi 20:9',
  '3 Nephi 20:10',
  '3 Nephi 20:11',
  '3 Nephi 20:12',
  '3 Nephi 20:13',
  '3 Nephi 20:14',
  '3 Nephi 20:15',
  '3 Nephi 20:16',
  '3 Nephi 20:17',
  '3 Nephi 20:18',
  '3 Nephi 20:19',
  '3 Nephi 20:20',
  '3 Nephi 20:21',
  '3 Nephi 20:22',
  '3 Nephi 20:23',
  '3 Nephi 20:24',
  '3 Nephi 20:25',
  '3 Nephi 20:26',
  '3 Nephi 20:27',
  '3 Nephi 20:28',
  '3 Nephi 20:29',
  '3 Nephi 20:30',
  '3 Nephi 20:31',
  '3 Nephi 20:32',
  '3 Nephi 20:33',
  '3 Nephi 20:34',
  '3 Nephi 20:35',
  '3 Nephi 20:36',
  '3 Nephi 20:37',
  '3 Nephi 20:38',
  '3 Nephi 20:39',
  '3 Nephi 20:40',
  '3 Nephi 20:41',
  '3 Nephi 20:42',
  '3 Nephi 20:43',
  '3 Nephi 20:44',
  '3 Nephi 20:45',
  '3 Nephi 20:46',
  '3 Nephi 21:1',
  '3 Nephi 21:2',
  '3 Nephi 21:3',
  '3 Nephi 21:4',
  '3 Nephi 21:5',
  '3 Nephi 21:6',
  '3 Nephi 21:7',
  '3 Nephi 21:8',
  '3 Nephi 21:9',
  '3 Nephi 21:10',
  '3 Nephi 21:11',
  '3 Nephi 21:12',
  '3 Nephi 21:13',
  '3 Nephi 21:14',
  '3 Nephi 21:15',
  '3 Nephi 21:16',
  '3 Nephi 21:17',
  '3 Nephi 21:18',
  '3 Nephi 21:19',
  '3 Nephi 21:20',
  '3 Nephi 21:21',
  '3 Nephi 21:22',
  '3 Nephi 21:23',
  '3 Nephi 21:24',
  '3 Nephi 21:25',
  '3 Nephi 21:26',
  '3 Nephi 21:27',
  '3 Nephi 21:28',
  '3 Nephi 21:29',
  '3 Nephi 22:1',
  '3 Nephi 22:2',
  '3 Nephi 22:3',
  '3 Nephi 22:4',
  '3 Nephi 22:5',
  '3 Nephi 22:6',
  '3 Nephi 22:7',
  '3 Nephi 22:8',
  '3 Nephi 22:9',
  '3 Nephi 22:10',
  '3 Nephi 22:11',
  '3 Nephi 22:12',
  '3 Nephi 22:13',
  '3 Nephi 22:14',
  '3 Nephi 22:15',
  '3 Nephi 22:16',
  '3 Nephi 22:17',
  '3 Nephi 23:1',
  '3 Nephi 23:2',
  '3 Nephi 23:3',
  '3 Nephi 23:4',
  '3 Nephi 23:5',
  '3 Nephi 23:6',
  '3 Nephi 23:7',
  '3 Nephi 23:8',
  '3 Nephi 23:9',
  '3 Nephi 23:10',
  '3 Nephi 23:11',
  '3 Nephi 23:12',
  '3 Nephi 23:13',
  '3 Nephi 23:14',
  '3 Nephi 24:1',
  '3 Nephi 24:2',
  '3 Nephi 24:3',
  '3 Nephi 24:4',
  '3 Nephi 24:5',
  '3 Nephi 24:6',
  '3 Nephi 24:7',
  '3 Nephi 24:8',
  '3 Nephi 24:9',
  '3 Nephi 24:10',
  '3 Nephi 24:11',
  '3 Nephi 24:12',
  '3 Nephi 24:13',
  '3 Nephi 24:14',
  '3 Nephi 24:15',
  '3 Nephi 24:16',
  '3 Nephi 24:17',
  '3 Nephi 24:18',
  '3 Nephi 25:1',
  '3 Nephi 25:2',
  '3 Nephi 25:3',
  '3 Nephi 25:4',
  '3 Nephi 25:5',
  '3 Nephi 25:6',
  '3 Nephi 26:1',
  '3 Nephi 26:2',
  '3 Nephi 26:3',
  '3 Nephi 26:4',
  '3 Nephi 26:5',
  '3 Nephi 26:6',
  '3 Nephi 26:7',
  '3 Nephi 26:8',
  '3 Nephi 26:9',
  '3 Nephi 26:10',
  '3 Nephi 26:11',
  '3 Nephi 26:12',
  '3 Nephi 26:13',
  '3 Nephi 26:14',
  '3 Nephi 26:15',
  '3 Nephi 26:16',
  '3 Nephi 26:17',
  '3 Nephi 26:18',
  '3 Nephi 26:19',
  '3 Nephi 26:20',
  '3 Nephi 26:21',
  '3 Nephi 27:1',
  '3 Nephi 27:2',
  '3 Nephi 27:3',
  '3 Nephi 27:4',
  '3 Nephi 27:5',
  '3 Nephi 27:6',
  '3 Nephi 27:7',
  '3 Nephi 27:8',
  '3 Nephi 27:9',
  '3 Nephi 27:10',
  '3 Nephi 27:11',
  '3 Nephi 27:12',
  '3 Nephi 27:13',
  '3 Nephi 27:14',
  '3 Nephi 27:15',
  '3 Nephi 27:16',
  '3 Nephi 27:17',
  '3 Nephi 27:18',
  '3 Nephi 27:19',
  '3 Nephi 27:20',
  '3 Nephi 27:21',
  '3 Nephi 27:22',
  '3 Nephi 27:23',
  '3 Nephi 27:24',
  '3 Nephi 27:25',
  '3 Nephi 27:26',
  '3 Nephi 27:27',
  '3 Nephi 27:28',
  '3 Nephi 27:29',
  '3 Nephi 27:30',
  '3 Nephi 27:31',
  '3 Nephi 27:32',
  '3 Nephi 27:33',
  '3 Nephi 28:1',
  '3 Nephi 28:2',
  '3 Nephi 28:3',
  '3 Nephi 28:4',
  '3 Nephi 28:5',
  '3 Nephi 28:6',
  '3 Nephi 28:7',
  '3 Nephi 28:8',
  '3 Nephi 28:9',
  '3 Nephi 28:10',
  '3 Nephi 28:11',
  '3 Nephi 28:12',
  '3 Nephi 28:13',
  '3 Nephi 28:14',
  '3 Nephi 28:15',
  '3 Nephi 28:16',
  '3 Nephi 28:17',
  '3 Nephi 28:18',
  '3 Nephi 28:19',
  '3 Nephi 28:20',
  '3 Nephi 28:21',
  '3 Nephi 28:22',
  '3 Nephi 28:23',
  '3 Nephi 28:24',
  '3 Nephi 28:25',
  '3 Nephi 28:26',
  '3 Nephi 28:27',
  '3 Nephi 28:28',
  '3 Nephi 28:29',
  '3 Nephi 28:30',
  '3 Nephi 28:31',
  '3 Nephi 28:32',
  '3 Nephi 28:33',
  '3 Nephi 28:34',
  '3 Nephi 28:35',
  '3 Nephi 28:36',
  '3 Nephi 28:37',
  '3 Nephi 28:38',
  '3 Nephi 28:39',
  '3 Nephi 28:40',
  '3 Nephi 29:1',
  '3 Nephi 29:2',
  '3 Nephi 29:3',
  '3 Nephi 29:4',
  '3 Nephi 29:5',
  '3 Nephi 29:6',
  '3 Nephi 29:7',
  '3 Nephi 29:8',
  '3 Nephi 29:9',
  '3 Nephi 30:1',
  '3 Nephi 30:2',
  '4 Nephi 1:1',
  '4 Nephi 1:2',
  '4 Nephi 1:3',
  '4 Nephi 1:4',
  '4 Nephi 1:5',
  '4 Nephi 1:6',
  '4 Nephi 1:7',
  '4 Nephi 1:8',
  '4 Nephi 1:9',
  '4 Nephi 1:10',
  '4 Nephi 1:11',
  '4 Nephi 1:12',
  '4 Nephi 1:13',
  '4 Nephi 1:14',
  '4 Nephi 1:15',
  '4 Nephi 1:16',
  '4 Nephi 1:17',
  '4 Nephi 1:18',
  '4 Nephi 1:19',
  '4 Nephi 1:20',
  '4 Nephi 1:21',
  '4 Nephi 1:22',
  '4 Nephi 1:23',
  '4 Nephi 1:24',
  '4 Nephi 1:25',
  '4 Nephi 1:26',
  '4 Nephi 1:27',
  '4 Nephi 1:28',
  '4 Nephi 1:29',
  '4 Nephi 1:30',
  '4 Nephi 1:31',
  '4 Nephi 1:32',
  '4 Nephi 1:33',
  '4 Nephi 1:34',
  '4 Nephi 1:35',
  '4 Nephi 1:36',
  '4 Nephi 1:37',
  '4 Nephi 1:38',
  '4 Nephi 1:39',
  '4 Nephi 1:40',
  '4 Nephi 1:41',
  '4 Nephi 1:42',
  '4 Nephi 1:43',
  '4 Nephi 1:44',
  '4 Nephi 1:45',
  '4 Nephi 1:46',
  '4 Nephi 1:47',
  '4 Nephi 1:48',
  '4 Nephi 1:49',
  'Mormon 1:1',
  'Mormon 1:2',
  'Mormon 1:3',
  'Mormon 1:4',
  'Mormon 1:5',
  'Mormon 1:6',
  'Mormon 1:7',
  'Mormon 1:8',
  'Mormon 1:9',
  'Mormon 1:10',
  'Mormon 1:11',
  'Mormon 1:12',
  'Mormon 1:13',
  'Mormon 1:14',
  'Mormon 1:15',
  'Mormon 1:16',
  'Mormon 1:17',
  'Mormon 1:18',
  'Mormon 1:19',
  'Mormon 2:1',
  'Mormon 2:2',
  'Mormon 2:3',
  'Mormon 2:4',
  'Mormon 2:5',
  'Mormon 2:6',
  'Mormon 2:7',
  'Mormon 2:8',
  'Mormon 2:9',
  'Mormon 2:10',
  'Mormon 2:11',
  'Mormon 2:12',
  'Mormon 2:13',
  'Mormon 2:14',
  'Mormon 2:15',
  'Mormon 2:16',
  'Mormon 2:17',
  'Mormon 2:18',
  'Mormon 2:19',
  'Mormon 2:20',
  'Mormon 2:21',
  'Mormon 2:22',
  'Mormon 2:23',
  'Mormon 2:24',
  'Mormon 2:25',
  'Mormon 2:26',
  'Mormon 2:27',
  'Mormon 2:28',
  'Mormon 2:29',
  'Mormon 3:1',
  'Mormon 3:2',
  'Mormon 3:3',
  'Mormon 3:4',
  'Mormon 3:5',
  'Mormon 3:6',
  'Mormon 3:7',
  'Mormon 3:8',
  'Mormon 3:9',
  'Mormon 3:10',
  'Mormon 3:11',
  'Mormon 3:12',
  'Mormon 3:13',
  'Mormon 3:14',
  'Mormon 3:15',
  'Mormon 3:16',
  'Mormon 3:17',
  'Mormon 3:18',
  'Mormon 3:19',
  'Mormon 3:20',
  'Mormon 3:21',
  'Mormon 3:22',
  'Mormon 4:1',
  'Mormon 4:2',
  'Mormon 4:3',
  'Mormon 4:4',
  'Mormon 4:5',
  'Mormon 4:6',
  'Mormon 4:7',
  'Mormon 4:8',
  'Mormon 4:9',
  'Mormon 4:10',
  'Mormon 4:11',
  'Mormon 4:12',
  'Mormon 4:13',
  'Mormon 4:14',
  'Mormon 4:15',
  'Mormon 4:16',
  'Mormon 4:17',
  'Mormon 4:18',
  'Mormon 4:19',
  'Mormon 4:20',
  'Mormon 4:21',
  'Mormon 4:22',
  'Mormon 4:23',
  'Mormon 5:1',
  'Mormon 5:2',
  'Mormon 5:3',
  'Mormon 5:4',
  'Mormon 5:5',
  'Mormon 5:6',
  'Mormon 5:7',
  'Mormon 5:8',
  'Mormon 5:9',
  'Mormon 5:10',
  'Mormon 5:11',
  'Mormon 5:12',
  'Mormon 5:13',
  'Mormon 5:14',
  'Mormon 5:15',
  'Mormon 5:16',
  'Mormon 5:17',
  'Mormon 5:18',
  'Mormon 5:19',
  'Mormon 5:20',
  'Mormon 5:21',
  'Mormon 5:22',
  'Mormon 5:23',
  'Mormon 5:24',
  'Mormon 6:1',
  'Mormon 6:2',
  'Mormon 6:3',
  'Mormon 6:4',
  'Mormon 6:5',
  'Mormon 6:6',
  'Mormon 6:7',
  'Mormon 6:8',
  'Mormon 6:9',
  'Mormon 6:10',
  'Mormon 6:11',
  'Mormon 6:12',
  'Mormon 6:13',
  'Mormon 6:14',
  'Mormon 6:15',
  'Mormon 6:16',
  'Mormon 6:17',
  'Mormon 6:18',
  'Mormon 6:19',
  'Mormon 6:20',
  'Mormon 6:21',
  'Mormon 6:22',
  'Mormon 7:1',
  'Mormon 7:2',
  'Mormon 7:3',
  'Mormon 7:4',
  'Mormon 7:5',
  'Mormon 7:6',
  'Mormon 7:7',
  'Mormon 7:8',
  'Mormon 7:9',
  'Mormon 7:10',
  'Mormon 8:1',
  'Mormon 8:2',
  'Mormon 8:3',
  'Mormon 8:4',
  'Mormon 8:5',
  'Mormon 8:6',
  'Mormon 8:7',
  'Mormon 8:8',
  'Mormon 8:9',
  'Mormon 8:10',
  'Mormon 8:11',
  'Mormon 8:12',
  'Mormon 8:13',
  'Mormon 8:14',
  'Mormon 8:15',
  'Mormon 8:16',
  'Mormon 8:17',
  'Mormon 8:18',
  'Mormon 8:19',
  'Mormon 8:20',
  'Mormon 8:21',
  'Mormon 8:22',
  'Mormon 8:23',
  'Mormon 8:24',
  'Mormon 8:25',
  'Mormon 8:26',
  'Mormon 8:27',
  'Mormon 8:28',
  'Mormon 8:29',
  'Mormon 8:30',
  'Mormon 8:31',
  'Mormon 8:32',
  'Mormon 8:33',
  'Mormon 8:34',
  'Mormon 8:35',
  'Mormon 8:36',
  'Mormon 8:37',
  'Mormon 8:38',
  'Mormon 8:39',
  'Mormon 8:40',
  'Mormon 8:41',
  'Mormon 9:1',
  'Mormon 9:2',
  'Mormon 9:3',
  'Mormon 9:4',
  'Mormon 9:5',
  'Mormon 9:6',
  'Mormon 9:7',
  'Mormon 9:8',
  'Mormon 9:9',
  'Mormon 9:10',
  'Mormon 9:11',
  'Mormon 9:12',
  'Mormon 9:13',
  'Mormon 9:14',
  'Mormon 9:15',
  'Mormon 9:16',
  'Mormon 9:17',
  'Mormon 9:18',
  'Mormon 9:19',
  'Mormon 9:20',
  'Mormon 9:21',
  'Mormon 9:22',
  'Mormon 9:23',
  'Mormon 9:24',
  'Mormon 9:25',
  'Mormon 9:26',
  'Mormon 9:27',
  'Mormon 9:28',
  'Mormon 9:29',
  'Mormon 9:30',
  'Mormon 9:31',
  'Mormon 9:32',
  'Mormon 9:33',
  'Mormon 9:34',
  'Mormon 9:35',
  'Mormon 9:36',
  'Mormon 9:37',
  'Ether 1:1',
  'Ether 1:2',
  'Ether 1:3',
  'Ether 1:4',
  'Ether 1:5',
  'Ether 1:6',
  'Ether 1:7',
  'Ether 1:8',
  'Ether 1:9',
  'Ether 1:10',
  'Ether 1:11',
  'Ether 1:12',
  'Ether 1:13',
  'Ether 1:14',
  'Ether 1:15',
  'Ether 1:16',
  'Ether 1:17',
  'Ether 1:18',
  'Ether 1:19',
  'Ether 1:20',
  'Ether 1:21',
  'Ether 1:22',
  'Ether 1:23',
  'Ether 1:24',
  'Ether 1:25',
  'Ether 1:26',
  'Ether 1:27',
  'Ether 1:28',
  'Ether 1:29',
  'Ether 1:30',
  'Ether 1:31',
  'Ether 1:32',
  'Ether 1:33',
  'Ether 1:34',
  'Ether 1:35',
  'Ether 1:36',
  'Ether 1:37',
  'Ether 1:38',
  'Ether 1:39',
  'Ether 1:40',
  'Ether 1:41',
  'Ether 1:42',
  'Ether 1:43',
  'Ether 2:1',
  'Ether 2:2',
  'Ether 2:3',
  'Ether 2:4',
  'Ether 2:5',
  'Ether 2:6',
  'Ether 2:7',
  'Ether 2:8',
  'Ether 2:9',
  'Ether 2:10',
  'Ether 2:11',
  'Ether 2:12',
  'Ether 2:13',
  'Ether 2:14',
  'Ether 2:15',
  'Ether 2:16',
  'Ether 2:17',
  'Ether 2:18',
  'Ether 2:19',
  'Ether 2:20',
  'Ether 2:21',
  'Ether 2:22',
  'Ether 2:23',
  'Ether 2:24',
  'Ether 2:25',
  'Ether 3:1',
  'Ether 3:2',
  'Ether 3:3',
  'Ether 3:4',
  'Ether 3:5',
  'Ether 3:6',
  'Ether 3:7',
  'Ether 3:8',
  'Ether 3:9',
  'Ether 3:10',
  'Ether 3:11',
  'Ether 3:12',
  'Ether 3:13',
  'Ether 3:14',
  'Ether 3:15',
  'Ether 3:16',
  'Ether 3:17',
  'Ether 3:18',
  'Ether 3:19',
  'Ether 3:20',
  'Ether 3:21',
  'Ether 3:22',
  'Ether 3:23',
  'Ether 3:24',
  'Ether 3:25',
  'Ether 3:26',
  'Ether 3:27',
  'Ether 3:28',
  'Ether 4:1',
  'Ether 4:2',
  'Ether 4:3',
  'Ether 4:4',
  'Ether 4:5',
  'Ether 4:6',
  'Ether 4:7',
  'Ether 4:8',
  'Ether 4:9',
  'Ether 4:10',
  'Ether 4:11',
  'Ether 4:12',
  'Ether 4:13',
  'Ether 4:14',
  'Ether 4:15',
  'Ether 4:16',
  'Ether 4:17',
  'Ether 4:18',
  'Ether 4:19',
  'Ether 5:1',
  'Ether 5:2',
  'Ether 5:3',
  'Ether 5:4',
  'Ether 5:5',
  'Ether 5:6',
  'Ether 6:1',
  'Ether 6:2',
  'Ether 6:3',
  'Ether 6:4',
  'Ether 6:5',
  'Ether 6:6',
  'Ether 6:7',
  'Ether 6:8',
  'Ether 6:9',
  'Ether 6:10',
  'Ether 6:11',
  'Ether 6:12',
  'Ether 6:13',
  'Ether 6:14',
  'Ether 6:15',
  'Ether 6:16',
  'Ether 6:17',
  'Ether 6:18',
  'Ether 6:19',
  'Ether 6:20',
  'Ether 6:21',
  'Ether 6:22',
  'Ether 6:23',
  'Ether 6:24',
  'Ether 6:25',
  'Ether 6:26',
  'Ether 6:27',
  'Ether 6:28',
  'Ether 6:29',
  'Ether 6:30',
  'Ether 7:1',
  'Ether 7:2',
  'Ether 7:3',
  'Ether 7:4',
  'Ether 7:5',
  'Ether 7:6',
  'Ether 7:7',
  'Ether 7:8',
  'Ether 7:9',
  'Ether 7:10',
  'Ether 7:11',
  'Ether 7:12',
  'Ether 7:13',
  'Ether 7:14',
  'Ether 7:15',
  'Ether 7:16',
  'Ether 7:17',
  'Ether 7:18',
  'Ether 7:19',
  'Ether 7:20',
  'Ether 7:21',
  'Ether 7:22',
  'Ether 7:23',
  'Ether 7:24',
  'Ether 7:25',
  'Ether 7:26',
  'Ether 7:27',
  'Ether 8:1',
  'Ether 8:2',
  'Ether 8:3',
  'Ether 8:4',
  'Ether 8:5',
  'Ether 8:6',
  'Ether 8:7',
  'Ether 8:8',
  'Ether 8:9',
  'Ether 8:10',
  'Ether 8:11',
  'Ether 8:12',
  'Ether 8:13',
  'Ether 8:14',
  'Ether 8:15',
  'Ether 8:16',
  'Ether 8:17',
  'Ether 8:18',
  'Ether 8:19',
  'Ether 8:20',
  'Ether 8:21',
  'Ether 8:22',
  'Ether 8:23',
  'Ether 8:24',
  'Ether 8:25',
  'Ether 8:26',
  'Ether 9:1',
  'Ether 9:2',
  'Ether 9:3',
  'Ether 9:4',
  'Ether 9:5',
  'Ether 9:6',
  'Ether 9:7',
  'Ether 9:8',
  'Ether 9:9',
  'Ether 9:10',
  'Ether 9:11',
  'Ether 9:12',
  'Ether 9:13',
  'Ether 9:14',
  'Ether 9:15',
  'Ether 9:16',
  'Ether 9:17',
  'Ether 9:18',
  'Ether 9:19',
  'Ether 9:20',
  'Ether 9:21',
  'Ether 9:22',
  'Ether 9:23',
  'Ether 9:24',
  'Ether 9:25',
  'Ether 9:26',
  'Ether 9:27',
  'Ether 9:28',
  'Ether 9:29',
  'Ether 9:30',
  'Ether 9:31',
  'Ether 9:32',
  'Ether 9:33',
  'Ether 9:34',
  'Ether 9:35',
  'Ether 10:1',
  'Ether 10:2',
  'Ether 10:3',
  'Ether 10:4',
  'Ether 10:5',
  'Ether 10:6',
  'Ether 10:7',
  'Ether 10:8',
  'Ether 10:9',
  'Ether 10:10',
  'Ether 10:11',
  'Ether 10:12',
  'Ether 10:13',
  'Ether 10:14',
  'Ether 10:15',
  'Ether 10:16',
  'Ether 10:17',
  'Ether 10:18',
  'Ether 10:19',
  'Ether 10:20',
  'Ether 10:21',
  'Ether 10:22',
  'Ether 10:23',
  'Ether 10:24',
  'Ether 10:25',
  'Ether 10:26',
  'Ether 10:27',
  'Ether 10:28',
  'Ether 10:29',
  'Ether 10:30',
  'Ether 10:31',
  'Ether 10:32',
  'Ether 10:33',
  'Ether 10:34',
  'Ether 11:1',
  'Ether 11:2',
  'Ether 11:3',
  'Ether 11:4',
  'Ether 11:5',
  'Ether 11:6',
  'Ether 11:7',
  'Ether 11:8',
  'Ether 11:9',
  'Ether 11:10',
  'Ether 11:11',
  'Ether 11:12',
  'Ether 11:13',
  'Ether 11:14',
  'Ether 11:15',
  'Ether 11:16',
  'Ether 11:17',
  'Ether 11:18',
  'Ether 11:19',
  'Ether 11:20',
  'Ether 11:21',
  'Ether 11:22',
  'Ether 11:23',
  'Ether 12:1',
  'Ether 12:2',
  'Ether 12:3',
  'Ether 12:4',
  'Ether 12:5',
  'Ether 12:6',
  'Ether 12:7',
  'Ether 12:8',
  'Ether 12:9',
  'Ether 12:10',
  'Ether 12:11',
  'Ether 12:12',
  'Ether 12:13',
  'Ether 12:14',
  'Ether 12:15',
  'Ether 12:16',
  'Ether 12:17',
  'Ether 12:18',
  'Ether 12:19',
  'Ether 12:20',
  'Ether 12:21',
  'Ether 12:22',
  'Ether 12:23',
  'Ether 12:24',
  'Ether 12:25',
  'Ether 12:26',
  'Ether 12:27',
  'Ether 12:28',
  'Ether 12:29',
  'Ether 12:30',
  'Ether 12:31',
  'Ether 12:32',
  'Ether 12:33',
  'Ether 12:34',
  'Ether 12:35',
  'Ether 12:36',
  'Ether 12:37',
  'Ether 12:38',
  'Ether 12:39',
  'Ether 12:40',
  'Ether 12:41',
  'Ether 13:1',
  'Ether 13:2',
  'Ether 13:3',
  'Ether 13:4',
  'Ether 13:5',
  'Ether 13:6',
  'Ether 13:7',
  'Ether 13:8',
  'Ether 13:9',
  'Ether 13:10',
  'Ether 13:11',
  'Ether 13:12',
  'Ether 13:13',
  'Ether 13:14',
  'Ether 13:15',
  'Ether 13:16',
  'Ether 13:17',
  'Ether 13:18',
  'Ether 13:19',
  'Ether 13:20',
  'Ether 13:21',
  'Ether 13:22',
  'Ether 13:23',
  'Ether 13:24',
  'Ether 13:25',
  'Ether 13:26',
  'Ether 13:27',
  'Ether 13:28',
  'Ether 13:29',
  'Ether 13:30',
  'Ether 13:31',
  'Ether 14:1',
  'Ether 14:2',
  'Ether 14:3',
  'Ether 14:4',
  'Ether 14:5',
  'Ether 14:6',
  'Ether 14:7',
  'Ether 14:8',
  'Ether 14:9',
  'Ether 14:10',
  'Ether 14:11',
  'Ether 14:12',
  'Ether 14:13',
  'Ether 14:14',
  'Ether 14:15',
  'Ether 14:16',
  'Ether 14:17',
  'Ether 14:18',
  'Ether 14:19',
  'Ether 14:20',
  'Ether 14:21',
  'Ether 14:22',
  'Ether 14:23',
  'Ether 14:24',
  'Ether 14:25',
  'Ether 14:26',
  'Ether 14:27',
  'Ether 14:28',
  'Ether 14:29',
  'Ether 14:30',
  'Ether 14:31',
  'Ether 15:1',
  'Ether 15:2',
  'Ether 15:3',
  'Ether 15:4',
  'Ether 15:5',
  'Ether 15:6',
  'Ether 15:7',
  'Ether 15:8',
  'Ether 15:9',
  'Ether 15:10',
  'Ether 15:11',
  'Ether 15:12',
  'Ether 15:13',
  'Ether 15:14',
  'Ether 15:15',
  'Ether 15:16',
  'Ether 15:17',
  'Ether 15:18',
  'Ether 15:19',
  'Ether 15:20',
  'Ether 15:21',
  'Ether 15:22',
  'Ether 15:23',
  'Ether 15:24',
  'Ether 15:25',
  'Ether 15:26',
  'Ether 15:27',
  'Ether 15:28',
  'Ether 15:29',
  'Ether 15:30',
  'Ether 15:31',
  'Ether 15:32',
  'Ether 15:33',
  'Ether 15:34',
  'Moroni 1:1',
  'Moroni 1:2',
  'Moroni 1:3',
  'Moroni 1:4',
  'Moroni 2:1',
  'Moroni 2:2',
  'Moroni 2:3',
  'Moroni 3:1',
  'Moroni 3:2',
  'Moroni 3:3',
  'Moroni 3:4',
  'Moroni 4:1',
  'Moroni 4:2',
  'Moroni 4:3',
  'Moroni 5:1',
  'Moroni 5:2',
  'Moroni 6:1',
  'Moroni 6:2',
  'Moroni 6:3',
  'Moroni 6:4',
  'Moroni 6:5',
  'Moroni 6:6',
  'Moroni 6:7',
  'Moroni 6:8',
  'Moroni 6:9',
  'Moroni 7:1',
  'Moroni 7:2',
  'Moroni 7:3',
  'Moroni 7:4',
  'Moroni 7:5',
  'Moroni 7:6',
  'Moroni 7:7',
  'Moroni 7:8',
  'Moroni 7:9',
  'Moroni 7:10',
  'Moroni 7:11',
  'Moroni 7:12',
  'Moroni 7:13',
  'Moroni 7:14',
  'Moroni 7:15',
  'Moroni 7:16',
  'Moroni 7:17',
  'Moroni 7:18',
  'Moroni 7:19',
  'Moroni 7:20',
  'Moroni 7:21',
  'Moroni 7:22',
  'Moroni 7:23',
  'Moroni 7:24',
  'Moroni 7:25',
  'Moroni 7:26',
  'Moroni 7:27',
  'Moroni 7:28',
  'Moroni 7:29',
  'Moroni 7:30',
  'Moroni 7:31',
  'Moroni 7:32',
  'Moroni 7:33',
  'Moroni 7:34',
  'Moroni 7:35',
  'Moroni 7:36',
  'Moroni 7:37',
  'Moroni 7:38',
  'Moroni 7:39',
  'Moroni 7:40',
  'Moroni 7:41',
  'Moroni 7:42',
  'Moroni 7:43',
  'Moroni 7:44',
  'Moroni 7:45',
  'Moroni 7:46',
  'Moroni 7:47',
  'Moroni 7:48',
  'Moroni 8:1',
  'Moroni 8:2',
  'Moroni 8:3',
  'Moroni 8:4',
  'Moroni 8:5',
  'Moroni 8:6',
  'Moroni 8:7',
  'Moroni 8:8',
  'Moroni 8:9',
  'Moroni 8:10',
  'Moroni 8:11',
  'Moroni 8:12',
  'Moroni 8:13',
  'Moroni 8:14',
  'Moroni 8:15',
  'Moroni 8:16',
  'Moroni 8:17',
  'Moroni 8:18',
  'Moroni 8:19',
  'Moroni 8:20',
  'Moroni 8:21',
  'Moroni 8:22',
  'Moroni 8:23',
  'Moroni 8:24',
  'Moroni 8:25',
  'Moroni 8:26',
  'Moroni 8:27',
  'Moroni 8:28',
  'Moroni 8:29',
  'Moroni 8:30',
  'Moroni 9:1',
  'Moroni 9:2',
  'Moroni 9:3',
  'Moroni 9:4',
  'Moroni 9:5',
  'Moroni 9:6',
  'Moroni 9:7',
  'Moroni 9:8',
  'Moroni 9:9',
  'Moroni 9:10',
  'Moroni 9:11',
  'Moroni 9:12',
  'Moroni 9:13',
  'Moroni 9:14',
  'Moroni 9:15',
  'Moroni 9:16',
  'Moroni 9:17',
  'Moroni 9:18',
  'Moroni 9:19',
  'Moroni 9:20',
  'Moroni 9:21',
  'Moroni 9:22',
  'Moroni 9:23',
  'Moroni 9:24',
  'Moroni 9:25',
  'Moroni 9:26',
  'Moroni 10:1',
  'Moroni 10:2',
  'Moroni 10:3',
  'Moroni 10:4',
  'Moroni 10:5',
  'Moroni 10:6',
  'Moroni 10:7',
  'Moroni 10:8',
  'Moroni 10:9',
  'Moroni 10:10',
  'Moroni 10:11',
  'Moroni 10:12',
  'Moroni 10:13',
  'Moroni 10:14',
  'Moroni 10:15',
  'Moroni 10:16',
  'Moroni 10:17',
  'Moroni 10:18',
  'Moroni 10:19',
  'Moroni 10:20',
  'Moroni 10:21',
  'Moroni 10:22',
  'Moroni 10:23',
  'Moroni 10:24',
  'Moroni 10:25',
  'Moroni 10:26',
  'Moroni 10:27',
  'Moroni 10:28',
  'Moroni 10:29',
  'Moroni 10:30',
  'Moroni 10:31',
  'Moroni 10:32',
  'Moroni 10:33',
  'Moroni 10:34',
];
